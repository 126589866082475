const CardData6 = [
 
  
  {
    title: 'The Cars',
    description:
      'The Cars fue una banda estadounidense de rock que surgió de la escena new wave a finales de la década de los 70s, proveniente de la ciudad de Boston, Massachusetts. Liderada por Ric Ocasek, y conformada además por Benjamin Orr, Greg Hawkes, Elliot Easton y David Robinson, se formaron a mediados de la década de los 70s en pleno despegue punk; en 1977 consiguieron un contrato discográfico con Elektra Records y en 1978 lanzaron su primer disco..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_l5YyP5yiyyDtxwd_BLim1yQBHbtJNk3Ww',
    price: 50,
    coverImg: 'thecars.jpeg',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://thecars.org/',
    youtube: 'https://www.youtube.com/channel/UCCuy1txs04hneBTVeB3r-4g',
    facebook: 'https://www.facebook.com/TheCars/',
    instagram: 'https://www.instagram.com/thecars/',
    twitter: 'https://twitter.com/thecarsband',
    spotify: 'https://open.spotify.com/playlist/6Awf6HdkNHUxJz4qgeYc4M',
    deezer: 'https://www.deezer.com/search/The%20Cars',
    apple: 'https://music.apple.com/mx/artist/the-cars/5132457',
    soundcloud: 'https://soundcloud.com/thecarsofficial',
    tidal: 'https://listen.tidal.com/artist/13594',
    wiki: 'https://es.wikipedia.org/wiki/The_Cars',
    ticketmaster:
      'https://www.ticketmaster.com/the-cars-tickets/artist/1017315',
    tiktok: 'https://www.tiktok.com/@thecars_777?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B00G7K9VKW/the-cars',
  },
  {
    title: 'Yes',
    description:
      'Yes es una banda británica de rock progresivo, formada en 1968 en Londres. Adquirió fama internacional durante la década de 1970 erigiéndose como uno de los pilares del género junto a Emerson, Lake & Palmer y King Crimson, además de Pink Floyd, Jethro Tull, Gentle Giant, Genesis, Focus, Camel y, más adelante, Rush.Influidos en sus inicios por grupos como The Beatles, The Who, The Moody Blues o Crosby, Stills, Nash and Young, rápidamente evolucionaron hacia un estilo propio caracterizado por complejas y recargadas armonías, la fusión de elementos del jazz y música clásica,[cita requerida] la creación de melódicas canciones y letras caracterizadas por su contenido enigmático, esotérico, onírico y poético, escritas mayormente por el vocalista, Jon Anderson...',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mlID3oJGeP1La5VV-J-1B_CIpwIOiH3Eo',
    price: 50,
    coverImg: 'yes.jpg',
    category: 'Rock Clasico',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.yesworld.com/',
    youtube: 'https://www.youtube.com/channel/UCJ2BSIyIU84ZtJ9MiLh0jZA',
    facebook: 'https://www.facebook.com/yestheband',
    instagram: 'https://www.instagram.com/yesofficial/',
    twitter: 'https://twitter.com/yesofficial',
    spotify:
      'https://open.spotify.com/artist/7AC976RDJzL2asmZuz7qil?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/2711',
    apple: 'https://music.apple.com/mx/artist/yes/154011',
    soundcloud: 'https://soundcloud.com/yesofficial',
    tidal: 'https://listen.tidal.com/artist/13686',
    wiki: 'https://es.wikipedia.org/wiki/Yes_(banda)',
    ticketmaster: 'https://www.ticketmaster.com/yes-tickets/artist/736506',
    tiktok: 'https://www.tiktok.com/search?lang=es&q=yesband&t=1692369621248',
    napster: 'https://music.amazon.com.mx/artists/B000QJOFSI/yes',
  },
  {
    title: 'SuperTramp',
    description:
      'Supertramp es un grupo británico de rock progresivo fundado en 1969 por el músico Rick Davies.4​ Es considerada una de las bandas más influyentes de la escena musical mundial de los años 70 y 80 del siglo xx, y ha vendido más de 70 millones de discos. La primera formación de Supertramp, integrada por Davies, Roger Hodgson, Richard Palmer-James y Robert Millar, publicó un álbum homónimo con las características del rock progresivo de escaso éxito comercial, seguido de un segundo trabajo, Indelibly Stamped, en el que Palmer y Millar fueron sustituidos por Frank Farrell, Kevin Currie y Dave Winthrop....',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kEAGtJHFf_4vohwmSTxESv70CCKaM3viQ',
    price: 50,
    coverImg: 'supertramp.jpg',
    category: 'Rock Clasico',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://supertramp.com/',
    youtube: 'https://www.youtube.com/channel/UCpS0hJ21amjA5eBApEOiIFw',
    facebook: 'https://www.facebook.com/profile.php?id=100044333834395',
    instagram: 'https://www.instagram.com/supertramp.superfans/',
    twitter: 'https://twitter.com/supertramp',
    spotify: 'https://open.spotify.com/artist/3JsMj0DEzyWc0VDlHuy9Bx',
    deezer: 'https://www.deezer.com/mx/artist/3128',
    apple: 'https://music.apple.com/us/artist/supertramp/43246',
    soundcloud: 'https://soundcloud.com/supertramp-official',
    tidal: 'https://listen.tidal.com/artist/29952',
    wiki: 'https://es.wikipedia.org/wiki/Supertramp',
    ticketmaster:
      'https://www.ticketmaster.com/supertramp-tickets/artist/736227',
    tiktok: 'https://www.tiktok.com/@supertramp143?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000V65VBG/supertramp',
  },
  {
    title: 'Bad Company',
    description:
      'Bad Company es un supergrupo británico de hard rock fundado en 1973 en Westminster, Londres. Originalmente estuvo integrado por exmiembros de la banda Free (Paul Rodgers, Simon Kirke), Mick Ralphs (exmiembro de Mott the Hoople) y Boz Burrell (exmiembro de King Crimson). Bad Company fue representado por Peter Grant, quien también guio a Led Zeppelin por éxitos masivos. La banda disfrutó de gran popularidad durante la década de 1970....',
    videourl: 'https://youtube.com/playlist?list=UULFfhZIFwsre2JoqgUQGyCsfw',
    price: 50,
    coverImg: 'badcompany.jpeg',
    category: 'Rock Clasico',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.badcompany.com/home/',
    youtube: 'https://www.youtube.com/channel/UCfhZIFwsre2JoqgUQGyCsfw',
    facebook: 'https://www.facebook.com/officialbadcompany',
    instagram: 'https://www.instagram.com/officialbadco/',
    twitter: 'https://twitter.com/officialbadco',
    spotify: 'https://open.spotify.com/artist/5AEG63ajney2BoDXi0Vb84',
    deezer: 'https://www.deezer.com/mx/artist/3613',
    apple: 'https://music.apple.com/us/artist/bad-company/3198995',
    soundcloud: 'https://soundcloud.com/badcompanymusic',
    tidal: 'https://listen.tidal.com/artist/14806',
    wiki: 'https://es.wikipedia.org/wiki/Bad_Company',
    ticketmaster:
      'https://www.ticketmaster.com/bad-company-tickets/artist/734487',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=Bad%20Company&t=1692371035458',
    napster: 'https://music.amazon.com.mx/artists/B000V65VBG/supertramp',
  },
  {
    title: 'Queen',
    description:
      'Queen es una banda británica de rock formada en 1970 en Londres por el cantante y pianista Freddie Mercury, el guitarrista Brian May y el baterista Roger Taylor. El bajista John Deacon llegaría un año después al grupo para completar la formación clásica. Tras el deceso de Mercury en 1991, y el retiro de Deacon en 1997, los integrantes restantes, Brian May y Roger Taylor, continúan trabajando bajo el nombre Queen o Queen +, por lo que la banda aún se considera activa.',
    videourl:
      'https://youtube.com/playlist?list=PLpTxN0bsTsj5GSZ4xME8tbhxez793WTob',
    price: '$50',
    coverImg: 'queen.jpeg',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://queenonline.com/',
    youtube: 'https://www.youtube.com/channel/UCiMhD4jzUqG-IgPzUmmytRQ',
    facebook: 'https://www.facebook.com/Queen',
    instagram: 'https://www.instagram.com/_queen_the_band_/',
    twitter: 'https://twitter.com/QueenWillRock',
    spotify: 'https://open.spotify.com/artist/1dfeR4HaWDbWqFHLkxsg1d',
    deezer: 'https://www.deezer.com/mx/artist/412',
    apple: 'https://music.apple.com/mx/artist/queen/3296287',
    soundcloud: 'https://soundcloud.com/queen-69312',
    tidal: 'https://listen.tidal.com/artist/8992',
    wiki: 'https://es.wikipedia.org/wiki/Queen',
    ticketmaster:
      'https://www.ticketmaster.com/queen-tribute-tickets/artist/2294420',
    tiktok: 'https://www.tiktok.com/@queenofficial',
    napster: 'https://music.amazon.com/artists/B000QK71AG/queen',
  },
  {
    title: 'Lynyrd Skynyrd',
    description:
      "Lynyrd Skynyrd es una banda de rock estadounidense formada en 1964 por el vocalista Ronnie Van Zant, los guitarristas Gary Rossington y Allen Collins, el batería Bob Burns y el bajista Larry Junstrom en Jacksonville, Florida. Originalmente, el grupo adoptó distintos nombres antes de decidirse por Lynyrd Skynyrd, en referencia al profesor de gimnasia de algunos de sus miembros. Entre 1973 y 1977, tras la llegada del teclista Billy Powell, del bajista Leon Wilkeson, del batería Artimus Pyle, y de los guitarristas Ed King y Steve Gaines, el conjunto grabó cinco álbumes de estudio:, Second Helping, Nuthin' Fancy, Gimme Back My Bullets y Street Survivors, de los cuales se lanzaron como sencillos sus dos canciones más conocidas, «Sweet Home Alabama» y «Free Bird». La revista Guitar World ubica a esta última en la posición #3 en su lista de los 100 mejores solos de guitarra de la historia. Ambas canciones figuran en el Salón de la Fama de los Grammy.",
    videourl: 'https://youtube.com/playlist?list=PL4652844CD61A1D17',
    price: 36,
    coverImg: 'lynrdSkynyrd.jpg',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://lynyrdskynyrd.com/',
    youtube: 'https://www.youtube.com/channel/UCifDYheLW7ZwCdSRAW0g1jw',
    facebook: 'https://www.facebook.com/LynyrdSkynyrd',
    instagram: 'https://www.instagram.com/skynyrd/',
    twitter: 'https://twitter.com/Skynyrd',
    spotify: 'https://open.spotify.com/artist/4MVyzYMgTwdP7Z49wAZHx0',
    deezer: 'https://www.deezer.com/mx/artist/816',
    apple: 'https://music.apple.com/mx/artist/lynyrd-skynyrd/97262',
    soundcloud: 'https://soundcloud.com/lynyrdskynyrd',
    tidal: 'https://listen.tidal.com/artist/14833',
    wiki: 'https://es.wikipedia.org/wiki/Lynyrd_Skynyrd',
    ticketmaster:
      'https://www.ticketmaster.com/lynyrd-skynyrd-tickets/artist/735560',
    tiktok: 'https://www.tiktok.com/@lynyrd_skynyrd_official?lang=es',
    napster: 'https://music.amazon.com/artists/B000QJOEES/lynyrd-skynyrd',
  },
  {
    title: 'Steve Miller Band',
    description:
      'The Steve Miller Band es una banda de rock estadounidense formada en 1966 en San Francisco, California. La banda está liderada por Steve Miller en la guitarra y la voz principal. El grupo tuvo una serie de sencillos exitosos de mediados a finales de la década de 1970 que son elementos básicos del rock clásico, así como varios álbumes anteriores de rock psicodélico. Miller dejó su primera banda para mudarse a San Francisco y formar la Steve Miller Blues Band. ',
    videourl:
      'https://youtube.com/playlist?list=PLFiSjHrjBmF41Cuc7XYr-t9gU1ThO7tkX',
    price: 36,
    coverImg: 'steveMiller.jpg',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.stevemillerband.com/',
    youtube: 'https://www.youtube.com/channel/UCEUwtVVBQ7_DwqwOo_ojErw',
    facebook: 'https://www.facebook.com/stevemillerband',
    instagram: 'https://www.instagram.com/stevemillerband/',
    twitter: 'https://twitter.com/SMBofficial',
    spotify: 'https://open.spotify.com/artist/6QtGlUje9TIkLrgPZrESuk',
    deezer: 'https://www.deezer.com/mx/artist/3351',
    apple: 'https://music.apple.com/mx/artist/steve-miller-band/488933',
    soundcloud: 'https://soundcloud.com/thestevemillerband',
    tidal: 'https://listen.tidal.com/artist/4029548',
    wiki: 'https://es.wikipedia.org/wiki/Steve_Miller_Band',
    ticketmaster:
      'https://www.ticketmaster.com/steve-miller-band-tickets/artist/856306',
    tiktok: 'https://www.tiktok.com/@stevemillerband?lang=es',
    napster: 'https://music.amazon.com/artists/B000RZBT12/the-steve-miller-band',
  },
  {
    title: 'Jethro Tull',
    description:
      'Jethro Tull es una banda de rock británica formada en Luton, Bedfordshire, en 1967. Inicialmente tocando blues rock y fusión de jazz, la banda pronto incorporó elementos de música folk inglesa, hard rock y música clásica, forjando un distintivo sonido de rock progresivo. El líder del grupo, fundador, compositor principal y único miembro constante es Ian Anderson, un multiinstrumentista que toca principalmente la flauta y la guitarra acústica, además de ser el vocalista principal. El grupo ha contado con una sucesión de músicos a lo largo de las décadas, incluyendo contribuyentes significativos como los guitarristas Mick Abrahams y Martin Barre (siendo Barre el miembro de más larga duración además de Anderson); los tecladistas John Evan, Dee Palmer, Peter-John Vettese y Andrew Giddings; los bateristas Clive Bunker, Barrie Barriemore, Barlow y Doane Perry; y los bajistas Glenn Cornick, Jeffrey Hammond, John Glascock, Dave Pegg y Jonathan Noyce..',
    videourl:
      'https://youtube.com/playlist?list=PLeVVPJRFgUTPBXGdOvrkXcRnh6KYICESI&si=NbdP3SXHgje-8wB_',
    price: '$50',
    coverImg: 'jethrotull.jpg',
    category: 'Classic Rock',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://jethrotull.com/',
    youtube: 'https://www.youtube.com/channel/UCyZe0Uc78q7aEA7bFKtZ-oA',
    facebook: 'https://www.facebook.com/officialjethrotull',
    instagram: 'https://www.instagram.com/jethrotull_/',
    twitter: 'https://twitter.com/jethrotull',
    spotify: 'https://open.spotify.com/artist/6w6z8m4WXX7Tub4Rb6Lu7R',
    deezer: 'https://www.deezer.com/mx/artist/2888',
    apple: 'https://music.apple.com/mx/artist/jethro-tull/44596725',
    soundcloud: 'https://soundcloud.com/jethrotullmusic',
    tidal: 'https://listen.tidal.com/artist/10597',
    wiki: 'https://en.wikipedia.org/wiki/Jethro_Tull_(band)',
    ticketmaster:
      'https://www.ticketmaster.com/jethro-tull-tickets/artist/735387',
    tiktok: 'https://www.tiktok.com/@jethrotullcollect',
    napster: 'https://music.amazon.com/artists/B000S9Y496/jethro-tull',
  },
  {
    title: 'War',
    description:
      "War (llamada inicialmente Eric Burdon & War) es una banda estadounidense de funk originaria de Long Beach, California. Fundada en 1969 y que se encuentra vigente hasta el día de hoy. Es la banda del exvocalista de The Animals. Uno de sus grandes hits, es el sencillo Low Rider, además de la famosa canción de 1975: Why Can't We Be Friends?..",
    videourl:
      'https://youtube.com/playlist?list=PLKzztqk1CWGnqkARbpvDmM-ptA6Z8uuN1',
    price: '$50',
    coverImg: 'war.jpeg',
    category: 'Funk & Groove',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://war.com/',
    youtube: 'https://www.youtube.com/c/war/videos',
    facebook: 'https://www.facebook.com/WARTHEBAND',
    instagram: 'https://www.instagram.com/wartheband/',
    twitter: 'https://twitter.com/WARtheBand',
    spotify: 'https://open.spotify.com/artist/3ICyfoySNDZqtBVmaBT84I',
    deezer: 'https://www.deezer.com/mx/artist/10129',
    apple: 'https://music.apple.com/mx/artist/war/261730408',
    soundcloud: 'https://soundcloud.com/war-official',
    tidal: 'https://listen.tidal.com/artist/3522622',
    wiki: 'https://es.wikipedia.org/wiki/War_(banda)',
    ticketmaster: 'https://www.ticketmaster.com/war-tickets/artist/736419',
    tiktok: 'https://www.tiktok.com/@warthebandofficial',
    napster: 'https://music.amazon.com/artists/B000QKH2L4/war',
  },
  {
    title: 'INXS',
    description:
      'INXS fue una banda australiana de rock, formada por los hermanos Farriss en 1977 en Sídney. Sus integrantes fueron: los hermanos Farriss, Andrew en teclados y compositor principal, Jon en batería y Tim en guitarra, Kirk Pengilly en guitarra y saxo, Garry Gary Beers en bajo y Michael Hutchence en voz, quien fue el letrista principal. El estilo musical de la banda fue inicialmente una mezcla de new wave, ska y pop, que luego evolucionó hacia un pop rock más duro, que incluía elementos de funk y dance..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_miyr2W5YJ2ezSu1a7A1he5ZGy167bvSBY',
    price: 36,
    coverImg: 'INXS.jpg',
    category: 'Rock',
    location: 'Australia',
    locationImg: 'australia.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.inxs.com/',
    youtube: 'https://www.youtube.com/channel/UCAldEKRD6LXGd8FAXI6Oo3w',
    facebook: 'https://www.facebook.com/inxs',
    instagram: 'https://www.instagram.com/officialinxs/',
    twitter: 'https://twitter.com/INXS',
    spotify: 'https://open.spotify.com/artist/1eClJfHLoDI4rZe5HxzBFv',
    deezer: 'https://www.deezer.com/mx/artist/1231',
    apple: 'https://music.apple.com/mx/artist/inxs/157405',
    soundcloud: 'https://soundcloud.com/inxs',
    tidal: 'https://listen.tidal.com/artist/5428',
    wiki: 'https://es.wikipedia.org/wiki/INXS',
    ticketmaster: 'https://www.ticketmaster.com/inxs-tickets/artist/735338',
    tiktok: 'https://www.tiktok.com/@inxs?lang=es',
    napster: 'https://music.amazon.com/artists/B000QJIDFO/inxs',
  },
  {
    title: 'Heart',
    description:
      'Heart es una banda estadounidense de Rock oriunda de Seattle formada por las hermanas Ann y Nancy Wilson, la banda fue fundada a principios de la década de 1970. Entre 1973 y 1976 la agrupación estuvo radicada en Vancouver, Canadá. Heart saltó a la fama a mediados de la década de 1970 con música influenciada por el hard rock, el heavy metal y la música folclórica. La banda gozó de gran popularidad alrededor del mundo desde mediados de los años 1970 hasta inicios de la década de 1980.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_k0XNCo69_dZ4D7GXA5ZLMPthlVMd2lN8U',
    price: 36,
    coverImg: 'Heart.jpg',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.heart-music.com/',
    youtube: 'https://www.youtube.com/channel/UCsFPCqkPsesPa8XxN1pJx-w',
    facebook: 'https://www.facebook.com/heart',
    instagram: 'https://www.instagram.com/heartofficial/',
    twitter: 'https://twitter.com/officialheart',
    spotify: 'https://open.spotify.com/artist/34jw2BbxjoYalTp8cJFCPv',
    deezer: 'https://www.deezer.com/mx/artist/7359',
    apple: 'https://music.apple.com/mx/artist/heart/487883',
    soundcloud: 'https://soundcloud.com/heartofficial',
    tidal: 'https://listen.tidal.com/artist/1002',
    wiki: 'https://es.wikipedia.org/wiki/Heart_(banda)',
    ticketmaster: 'https://www.ticketmaster.com/heart-tickets/artist/735257',
    tiktok:
      'https://www.tiktok.com/search/video?lang=es&q=heart%20band&t=1654385111234',
    napster: 'https://music.amazon.com/artists/B000QJNS5Y/heart',
  },
  {
    title: 'The Bangles',
    description:
      'The Bangles es una banda de pop rock estadounidense, uno de los primeros grupos formados exclusivamente por mujeres (no grupo vocal) a principios de los años ochenta, influidas por la new wave y un tanto del estilo garage rock.The Bangles se formó en Los Ángeles, California en 1981, originalmente bajo el nombre de The Supersonic Bangs, que luego se acortó a The Bangs. Sin embargo, un grupo de Nueva Jersey también llamado "The Bangs" amenazó con demandarlas, por lo que cambiaron su nombre a The Bangles (por su similitud con The Beatles).',
    videourl:
      'https://youtube.com/playlist?list=PLqkEPwNhwN7ksohvwCZSAcZEXYk18ILXk&si=MrFCeWwfCzIPeN24',
    price: 36,
    coverImg: 'bangles.jpg',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.thebangles.com/',
    youtube: 'https://www.youtube.com/user/TheBanglesMusic',
    facebook: 'https://www.facebook.com/thebangles/',
    instagram: 'https://www.instagram.com/officialthebangles/',
    twitter: 'https://x.com/OfficialBangles',
    spotify: 'https://open.spotify.com/artist/51l0uqRxGaczYr4271pVIC',
    deezer: 'https://www.deezer.com/mx/artist/994',
    apple: 'https://music.apple.com/mx/artist/the-bangles/3932789',
    soundcloud: 'https://soundcloud.com/the-bangles-official',
    tidal: 'https://listen.tidal.com/search?q=the%20bangles',
    wiki: 'https://es.wikipedia.org/wiki/The_Bangles',
    ticketmaster: 'https://www.ticketmaster.com/the-bangles-tickets/artist/863430',
    tiktok:
      'https://www.tiktok.com/@the_bangles?lang=es',
    napster: 'https://music.amazon.com/artists/B00G663ANQ/the-bangles',
  },
  {
    title: 'STYX',
    description:
      'Styx es una banda de rock estadounidense formada en Chicago, Illinois, como “The Tradewinds”. El grupo se caracteriza por combinar elementos del rock progresivo,​ el hard rock, el AOR y la power ballad.La banda logró reconocimiento mundial por las siguientes canciones que ingresaron en el Top 10 de Billboard: Lady, Come Sail Away, Babe, The Best of Times, Too Much Time on My Hands, Mr. Roboto, Don t Let It End y Show Me the Way. Otras canciones notables de la agrupación incluyen a Renegade, The Grand Illusion, Blue Collar Man, Crystal Ball, "Fooling Yourself (The Angry Young Man), Rockin the Paradise, Boat on the River y Suite Madame Blue. Styx alcanzó la cifra de cuatro álbumes consecutivos certificados multiplatino por la RIAA.De sus ocho sencillos Top 10, siete fueron escritos y cantados por el músico fundador Dennis DeYoung, incluyendo el número uno, Babe.​ DeYoung abandonó la agrupación definitivamente en 1999 después de ser despedido por los guitarristas James J.Y. Young y Tommy Shaw en una agria disputa.',
    videourl:
      'https://www.youtube.com/playlist?list=OLAK5uy_lZb3rd7yHXh3BQ4Mu0z1KCx-3pS_KoCHY',
    price: 36,
    coverImg: 'styx.jpg',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://styxworld.com/',
    youtube: 'https://www.youtube.com/@styxtheband',
    facebook: 'https://www.facebook.com/styxtheband',
    instagram: 'https://www.instagram.com/styxtheband/',
    twitter: 'https://x.com/STYXtheBand',
    spotify: 'https://open.spotify.com/artist/4salDzkGmfycRqNUbyBphh',
    deezer: 'https://www.deezer.com/mx/artist/5660',
    apple: 'https://music.apple.com/us/artist/styx/132659',
    soundcloud: 'https://soundcloud.com/styx-official',
    tidal: 'https://listen.tidal.com/artist/24876',
    wiki: 'https://es.wikipedia.org/wiki/Styx_(banda)',
    ticketmaster: 'https://www.ticketmaster.com/styx-tickets/artist/736216',
    tiktok:'https://www.tiktok.com/@styxtheband?lang=es',
    napster: 'https://music.amazon.com/artists/B000QJPG36/styx',
  },
  {
    title: 'Ted Nugent',
    description:
      'Theodore Anthony - Ted Nugent (Redford, Míchigan; 13 de diciembre de 1948) es un guitarrista, cantante, compositor y activista republicano estadounidense. Obtuvo reconocimiento inicialmente como guitarrista de la banda The Amboy Dukes, una agrupación formada en 1963 que tocaba rock psicodélico y hard rock. Tras su experiencia con The Amboy Dukes, inició una exitosa carrera en solitario,​',
    videourl:
      'https://www.youtube.com/playlist?list=OLAK5uy_kX4qeyYgQnLP5DI67HUGY7aQIHcK7MKRk',
    price: 36,
    coverImg: 'tednugent.gif',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.tednugent.com/',
    youtube: 'https://www.youtube.com/channel/UCB6clUGV3bIoHJ5IzNF4y0Q',
    facebook: 'https://www.facebook.com/tednugentamerican',
    instagram: 'https://www.instagram.com/tednugentofficial/',
    twitter: 'https://x.com/TedNugent',
    spotify:
      'https://open.spotify.com/artist/21ysNsPzHdqYN2fQ75ZswG',
    deezer: 'https://www.deezer.com/mx/artist/7178',
    apple: 'https://music.apple.com/mx/artist/ted-nugent/462527',
    soundcloud: 'https://soundcloud.com/ted-nugent-official',
    tidal: 'https://listen.tidal.com/artist/1012',
    wiki: 'https://es.wikipedia.org/wiki/Ted_Nugent',
    ticketmaster:
      'https://www.ticketmaster.com/ted-nugent-tickets/artist/735775',
    tiktok: 'https://www.tiktok.com/@officialtednuguent?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJR4LI/ted-nugent',
  },
  {
    title: 'Deep Purple',
    description:
      'Deep Purple es una banda británica de hard rock fundada en 1968 en Hertford, Reino Unido. La banda es considerada como una de las mejores y pionera de dicho género, además de influir a futuras bandas de rock y heavy metal junto a Black Sabbath y Led Zeppelin. Su música ha incorporado elementos del rock progresivo, rock sinfónico, rock psicodélico, blues rock, funk rock, britpop y música clásica. En su historia, Deep Purple ha vendido más de 150 millones de discos en todo el mundo.,​',
    videourl:
      'https://www.youtube.com/playlist?list=OLAK5uy_kX4qeyYgQnLP5DI67HUGY7aQIHcK7MKRk',
    price: 36,
    coverImg: 'deeppurple.jpg',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://deeppurple.tv/',
    youtube: 'https://www.youtube.com/channel/UCB6clUGV3bIoHJ5IzNF4y0Q',
    facebook: 'https://www.facebook.com/deeppurple6876',
    instagram: 'https://www.instagram.com/deeppurple_official/',
    twitter: 'https://x.com/_DeepPurple',
    spotify:
      'https://open.spotify.com/artist/568ZhdwyaiCyOGJRtNYhWf',
    deezer: 'https://www.deezer.com/mx/artist/864',
    apple: 'https://music.apple.com/us/artist/deep-purple/135532',
    soundcloud: 'https://soundcloud.com/deeppurple',
    tidal: 'https://listen.tidal.com/artist/3355',
    wiki: 'https://es.wikipedia.org/wiki/Deep_Purple',
    ticketmaster:
      'https://www.ticketmaster.com/deep-purple-tickets/artist/734897',
    tiktok: 'https://www.tiktok.com/@deeppurple_official?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJNHU0/deep-purple',
  },
  {
    title: 'Blue Oyster Cult',
    description:
      'Blue Öyster Cult es un grupo estadounidense de rock psicodélico y heavy metal formado en Long Island, Nueva York en 1967.El embrión de Blue Öyster Cult se remonta a 1967, cuando un grupo de jóvenes músicos neoyorquinos forman el grupo psicodélico Cows, nombre que más tarde cambiarían a Soft White Underbelly (algo así como «bajo vientre blanco y blando», frase acuñada durante la Segunda Guerra Mundial por Winston Churchill para referirse a Italia); el nombre fue propuesto por el letrista y periodista musical Sandy Pearlman, colaborador de la banda desde su origen, y quien a la larga sería su mánager y productor histórico.​',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mJY4gNUuqGNblQy5A_u65z2E-q8LicU1c&si=hlM4eK-74Ll-WrWW',
    price: 36,
    coverImg: 'blueoystercult.gif',
    category: 'Classic Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.blueoystercult.com/',
    youtube: 'https://www.youtube.com/channel/UCToiTDJNmKtyBHAUUsbdZnQ',
    facebook: 'https://www.facebook.com/blueoystercult/',
    instagram: 'https://www.instagram.com/blue_oystercult/',
    twitter: 'https://twitter.com/bocfanclub',
    spotify:
      'https://open.spotify.com/artist/00tVTdpEhQQw1bqdu8RCx2?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/692',
    apple: 'https://music.apple.com/mx/artist/blue-%C3%B6yster-cult/472017',
    soundcloud: 'https://soundcloud.com/blue-oyster-cult',
    tidal: 'https://listen.tidal.com/artist/483',
    wiki: 'https://es.wikipedia.org/wiki/Blue_%C3%96yster_Cult',
    ticketmaster:
      'https://www.ticketmaster.com/blue-oyster-cult-tickets/artist/734593',
    tiktok: 'https://www.tiktok.com/@blueoystercult?lang=es',
    napster: 'https://music.amazon.com/artists/B000QJNYP8/blue-oyster-cult',
  },
  {
    title: 'The Beatles',
    description:
      'The Beatles, también conocida en el mundo hispano como los Beatles, fue una banda de rock inglesa formada en Liverpool durante los años 1960, estando integrada desde 1962 a su separación en 1970 por John Lennon, Paul McCartney, George Harrison y Ringo Starr. Es ampliamente considerada como la banda más influyentes de todos los tiempos, siendo parte fundamental en el desarrollo del movimiento contracultural de la década de 1960 y el reconocimiento de la música popular como forma de arte.',
    videourl:
      'https://youtube.com/playlist?list=PL0jp-uZ7a4g8b4orD6OrbsVBigPO-EX9d',
    price: 36,
    coverImg: 'beatles.gif',
    category: 'Rock',
    location: 'Uk',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.thebeatles.com/',
    youtube: 'https://www.youtube.com/%20the%20beatles',
    facebook: 'https://www.facebook.com/thebeatles',
    instagram: 'https://www.instagram.com/thebeatles/',
    twitter: 'https://twitter.com/beatlesstory',
    spotify: 'https://open.spotify.com/artist/3WrFJ7ztbogyGnTHbHJFl2',
    deezer: 'https://www.deezer.com/mx/artist/1',
    apple: 'https://music.apple.com/mx/artist/the-beatles/136975',
    soundcloud:
      'https://soundcloud.com/search?q=the%20beatles%20yesterday&query_urn=soundcloud%3Asearch-autocomplete%3A6dceb916135b486a97ed6e5757c264fe',
    tidal: 'https://listen.tidal.com/artist/3634161',
    wiki: 'https://es.wikipedia.org/wiki/The_Beatles',
    ticketmaster:
      'https://www.ticketmaster.com/tribute-to-the-beatles-tickets/artist/2281760',
    tiktok: 'https://www.tiktok.com/@thebeatles?lang=es',
    napster: 'https://music.amazon.com/artists/B00GB0QTOY/the-beatles',
  },
  {
    title: 'Led Zeppelin',
    description:
      'Led Zeppelin fue un grupo británico de rock fundado en Londres en 1968 por el guitarrista Jimmy Page, quien había pertenecido a The Yardbirds. La banda estuvo integrada por Jimmy Page en la guitarra, John Paul Jones como bajista y tecladista, el vocalista Robert Plant y John Bonham en la batería. Es considerada una de las bandas más importantes e influyentes de la década de los 70 y de la historia del rock.',
    videourl:
      'http://youtube.com/playlist?list=PLMmd10177iHvLG5bgCGb-wxSbWD7TLRNf',
    price: 36,
    coverImg: 'ledzepellin.gif',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://merch.ledzeppelin.com/?ref=https://www.bing.com/',
    youtube: 'https://www.youtube.com/c/ledzeppelinofficial',
    facebook: 'https://www.facebook.com/ledzeppelin/',
    instagram: 'https://www.instagram.com/ledzeppelin/',
    twitter: 'https://twitter.com/ledzeppelin',
    spotify: 'https://open.spotify.com/artist/36QJpDe2go2KgaRleHCDTp',
    deezer: 'https://www.deezer.com/mx/artist/848',
    apple: 'https://music.apple.com/mx/artist/led-zeppelin/994656',
    soundcloud: 'https://soundcloud.com/search?q=Led%20Zepellin',
    tidal: 'https://listen.tidal.com/artist/67522',
    wiki:
      'https://www.ticketmaster.com/led-zeppelin-show-tickets/artist/2873806',
    ticketmaster: 'https://es.wikipedia.org/wiki/Led_Zeppelin',
    tiktok: 'https://www.tiktok.com/@ledzeppelin?lang=es',
    napster: 'https://music.amazon.com/artists/B000QKO05A/led-zeppelin',
  },

  {
    title: 'The Doors',
    description:
      'The Doors fue una banda de rock estadounidense, formada en Los Ángeles (California), en julio del año 1965 y disuelta en el año 1973.Aunque la carrera de The Doors terminó en 1973, su popularidad ha persistido. Según la RIAA, han vendido 33 millones de unidades certificadas en los Estados Unidos. La banda ha vendido más de 100 millones de álbumes en todo el mundo. En 2004, Rolling Stone clasificó a The Doors, en el puesto 41 en su lista de los 100 mejores artistas de todos los tiempos. Fue la primera banda estadounidense en acumular ocho discos de oro consecutivos. En 1993, The Doors fue incluido en el Rock and Roll Hall of Fame.',
    videourl:
      'http://youtube.com/playlist?list=PLaomso0d9kl6k8eO2Yl4bVtqbQ3wqgmt1',
    price: 80,
    coverImg: 'doors.gif',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://thedoors.com/',
    youtube: 'https://www.youtube.com/channel/UCYgJ2M1mq8Ae0QOm_VQU4VQ',
    facebook: 'https://www.facebook.com/thedoors',
    instagram: 'https://www.instagram.com/thedoors/',
    twitter: 'https://twitter.com/thedoors',
    spotify: 'https://open.spotify.com/artist/22WZ7M8sxp5THdruNY3gXt',
    deezer: 'https://www.deezer.com/mx/artist/847',
    apple: 'https://music.apple.com/mx/artist/the-doors/1248588',
    soundcloud: 'https://soundcloud.com/thedoors',
    tidal: 'https://listen.tidal.com/artist/13596',
    wiki: 'https://es.wikipedia.org/wiki/The_Doors',
    ticketmaster:
      'https://www.ticketmaster.com/the-doors-tickets/artist/734947',
    tiktok: 'https://www.tiktok.com/@thedoors?lang=es',
    napster: 'https://music.amazon.com/artists/B00G798T5Q/the-doors',
  },

  {
    title: 'Pink Floyd',
    description:
      'Pink Floyd fue una banda de rock británica, fundada en Londres en 1965. Considerada un ícono cultural del siglo XX y una de las bandas más influyentes, exitosas y aclamadas en la historia de la música popular, obtuvo gran popularidad dentro del circuito underground gracias a su música psicodélica y espacial, que con el paso del tiempo evolucionó hacia el rock progresivo y el rock sinfónico adquiriendo la popularidad con la que hoy son recordados. Es conocida por sus canciones de alto contenido filosófico, a veces de crítica política, junto a la experimentación sonora, las innovadoras portadas de sus discos y sus elaborados espectáculos en vivo. Sus ventas sobrepasan los 280 millones de álbumes vendidos en todo el mundo, 97.5 millones de ellos solamente en los Estados Unidos,convirtiéndose en una de las bandas con más ventas en la historia.',
    videourl:
      'http://youtube.com/playlist?list=PL0SqtI95X6o32fHymKzjz9uNVAFfd8ikk',
    price: 80,
    coverImg: 'pinkfloyd.gif',
    category: 'Rock',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.pinkfloyd.com/',
    youtube: 'https://www.youtube.com/channel/UCY2qt3dw2TQJxvBrDiYGHdQ',
    facebook: 'https://www.facebook.com/pinkfloyd',
    instagram: 'https://www.instagram.com/pinkfloyd/',
    twitter: 'https://twitter.com/pinkfloyd',
    spotify: 'https://open.spotify.com/artist/0k17h0D3J5VfsdmQ1iZtE9',
    deezer: 'https://www.deezer.com/mx/artist/860',
    apple: 'https://music.apple.com/mx/artist/pink-floyd/487143',
    soundcloud: 'https://soundcloud.com/officialpinkfloyd',
    tidal: 'https://listen.tidal.com/artist/9706',
    wiki: 'https://es.wikipedia.org/wiki/Pink_Floyd',
    ticketmaster:
      'https://www.ticketmaster.com/pink-floyd-tickets/artist/768805',
    tiktok: 'https://www.tiktok.com/@pink_floyd90?lang=es',
    napster: 'https://music.amazon.com/artists/B000QJPQK4/pink-floyd',
  },
  {
    title: 'Rush',
    description:
      'Rush fue una banda canadiense de rock progresivo formada en agosto de 1968 en el barrio de Willowdale de Toronto, Ontario. La formación se mantuvo estable desde 1974, cuando Neil Peart reemplazó al baterista original, John Rutsey, antes de su primera gira estadounidense. Estuvo integrada por el bajista, teclista y cantante Geddy Lee, el guitarrista Alex Lifeson y el batería y letrista Neil Peart..',
    videourl:
      'https://youtube.com/playlist?list=PLyzOXHX-1l7dNOm_KqOqM9q-W76gVPclW',
    price: '$50',
    coverImg: 'rush.gif',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.rush.com/',
    youtube: 'https://www.youtube.com/%20rush',
    facebook: 'https://www.facebook.com/rushtheband',
    instagram: 'https://www.instagram.com/rush/',
    twitter: 'https://twitter.com/rushtheband',
    spotify: 'https://open.spotify.com/artist/2Hkut4rAAyrQxRdof7FVJq',
    deezer: 'https://www.deezer.com/mx/artist/8686',
    apple: 'https://music.apple.com/mx/artist/rush/50526',
    soundcloud: 'https://soundcloud.com/rush-official',
    tidal: 'https://listen.tidal.com/artist/19141',
    wiki: 'https://es.wikipedia.org/wiki/Rush',
    ticketmaster: 'https://www.ticketmaster.com/rush-tickets/artist/807344',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=rush%20band%20songs&t=1654463060218',
    napster: 'https://music.amazon.com/artists/B000QJHSJ6/rush',
  },

  {
    title: 'Jimi Hendrix',
    description:
      'James Marshall Hendrix, más conocido como Jimi Hendrix, fue un guitarrista, cantante y compositor estadounidense. A pesar de que su carrera profesional solo duró cuatro años, es considerado uno de los músicos y guitarristas más influyentes de la historia del rock. El Salón de la Fama del Rock and Roll lo describe como «Indiscutiblemente uno de los músicos más grandes de la historia del rock.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlVsOwjLHHJTyFmL6D1GDA6e',
    price: '$50',
    coverImg: 'jimi-hendrix.gif',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.jimihendrix.com/',
    youtube: 'https://www.youtube.com/channel/UCEqrtYLjy1o4hvaUI0J530w',
    facebook: 'https://www.facebook.com/JimiHendrix',
    instagram: 'https://www.instagram.com/jimihendrix/',
    twitter: 'https://twitter.com/jimihendrix',
    spotify: 'https://open.spotify.com/artist/776Uo845nYHJpNaStv1Ds4',
    deezer: 'https://www.deezer.com/mx/artist/849',
    apple: 'https://music.apple.com/mx/artist/jimi-hendrix/62852',
    soundcloud: 'https://soundcloud.com/jimihendrix',
    tidal: 'https://listen.tidal.com/artist/30192',
    wiki: 'https://es.wikipedia.org/wiki/Jimi_Hendrix',
    ticketmaster:
      'https://www.ticketmaster.com/jimi-hendrix-tickets/artist/1409042',
    tiktok: 'https://www.tiktok.com/@thejimihendrixexperience?lang=es',
    napster: 'https://music.amazon.com/artists/B000QJIE9Y/jimi-hendrix',
  },
  {
    title: 'Santana',
    description:
      'Carlos Humberto Santana Barragan (Autlán de Navarro, Jalisco; 20 de julio de 1947) es un guitarrista mexicano nacionalizado estadounidense.En 1966 fundó la banda Santana, pionera en fusionar la música latina con el rock. Santana ha vendido más de 100 millones de álbumes en todo el mundo (contando las ventas de su banda y su carrera en solitario). Ha ganado diez premios Grammy y tres premios Grammy Latino.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_nFhKKg7Ch_9lnfxTogq0VhkZZdvB7wBg4',
    price: 50,
    coverImg: 'santana.gif',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.santana.com/',
    youtube: 'https://www.youtube.com/channel/UCI7pNDDat9jR8TjgF48aBDA',
    facebook: 'https://www.facebook.com/carlossantana',
    instagram: 'https://www.instagram.com/carlossantana/',
    twitter: 'https://twitter.com/santanacarlos',
    spotify: 'https://open.spotify.com/artist/6GI52t8N5F02MxU0g5U69P',
    deezer: 'https://www.deezer.com/mx/artist/553',
    apple: 'https://music.apple.com/mx/artist/santana/217174',
    soundcloud: 'https://soundcloud.com/santana-official',
    tidal: 'https://listen.tidal.com/artist/74',
    wiki: 'https://es.wikipedia.org/wiki/Carlos_Santana',
    ticketmaster: 'https://www.ticketmaster.com/santana-tickets/artist/741473',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=Santana%20music&t=1654465241940',
    napster: 'https://music.amazon.com/artists/B000QJK956/carlos-santana',
  },
  {
    title: 'The Rolling Stones',
    description:
      'The Rolling Stones es una banda británica de rock originaria de Londres. La banda se formó en abril de 1962, por Brian Jones, Mick Jagger, Keith Richards e Ian Stewart, junto a varios bajistas y baterías que nunca fueron fijos hasta la llegada de Bill Wyman y Charlie Watts unos meses después, los cuales completarían la primera formación de la banda. Ian Stewart dejó de ser miembro oficial del grupo a mediados de 1963, aunque permaneció en el mismo hasta su fallecimiento en 1985. Brian Jones fue despedido en junio de 1969 (fallecería tres semanas después), y fue reemplazado por el guitarrista Mick Taylor, que dejaría el grupo en 1975 y sería, a su vez, reemplazado por Ronnie Wood. Con el retiro de Bill Wyman en 1993 se incluyó al bajista Darryl Jones que, aunque toca con la banda desde la grabación del álbum Voodoo Lounge en 1994, no es un miembro oficial.',
    videourl:
      'http://youtube.com/playlist?list=PLIEx7PjdcpR5exzF_rQHxlP7Y7tqN2srX',
    price: 80,
    coverImg: 'rollingstones.gif',
    category: 'Rock Clasico',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://rollingstones.com/',
    youtube: 'https://www.youtube.com/channel/UCB_Z6rBg3WW3NL4-QimhC2A',
    facebook: 'https://www.facebook.com/therollingstones',
    instagram: 'https://www.instagram.com/therollingstones/',
    twitter: 'https://twitter.com/RollingStones',
    spotify: 'https://open.spotify.com/playlist/6gV6RlpjRDCbVB7z0M0b0p',
    deezer: 'https://www.deezer.com/mx/artist/11',
    apple: 'https://music.apple.com/mx/artist/the-rolling-stones/1249595',
    soundcloud: 'https://soundcloud.com/rolling-stones-official',
    tidal: 'https://listen.tidal.com/artist/573',
    wiki: 'https://es.wikipedia.org/wiki/The_Rolling_Stones',
    ticketmaster:
      'https://www.ticketmaster.com/the-rolling-stones-tickets/artist/806216',
    tiktok: 'https://www.tiktok.com/@rollingstonesoffical?lang=es',
    napster: 'https://music.amazon.com/artists/B000QJI6RY/the-rolling-stones',
  },{
    title: 'Billy Idol',
    description:
      'William Michael Albert Broad (30 de noviembre de 1955), conocido como Billy Idol, es un músico de rock y actor británico-estadounidense. Inicialmente obtuvo reconocimiento como cantante de la banda de punk Generation X. Subsecuentemente, Idol se embarcó en una exitosa carrera como solista durante 46 años de carrera, lo que le valió la fama internacional. Idol inició su carrera en 1976 como guitarrista en la banda de punk Chelsea. Sin embargo, pronto abandonó el grupo junto a su compañero Tony James para formar Generation X, banda en la que cantó por primera vez. El grupo obtuvo un relativo éxito comercial en el Reino Unido, y logró publicar tres álbumes de estudio con Chrysalis Records antes de separarse',
    videourl:
      'https://youtube.com/playlist?list=PLnsvoCj3vL9k1GF6g8D99WSMrs73OvJf6&si=sVRdmH-3ZkjvbhyY',
    price: 50,
    coverImg: 'billyidol.gif',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://billyidol.net/',
    youtube: 'https://www.youtube.com/channel/UCI7pNDDat9jR8TjgF48aBDA',
    facebook: 'https://www.facebook.com/BillyIdol/',
    instagram: 'https://www.instagram.com/billyidol/',
    twitter: 'https://x.com/billyidol',
    spotify: 'https://open.spotify.com/artist/7lzordPuZEXxwt9aoVZYmG',
    deezer: 'https://www.deezer.com/mx/artist/2111',
    apple: 'https://music.apple.com/mx/artist/billy-idol/550949',
    soundcloud: 'https://soundcloud.com/official-billy-idol',
    tidal: 'https://listen.tidal.com/artist/10283',
    wiki: 'https://es.wikipedia.org/wiki/Billy_Idol',
    ticketmaster: 'https://www.ticketmaster.com/billy-idol-tickets/artist/735323',
    tiktok:
      'https://www.tiktok.com/@official_billyidol77?lang=es',
    napster: 'https://music.amazon.com/artists/B000QJNH1Y/billy-idol',
  },
  {
    title: 'Aerosmith',
    description:
      'Aerosmith es una banda estadounidense de rock ​ formada en Boston en 1970. Está integrada por Steven Tyler (cantante), Joe Perry (guitarra), Tom Hamilton (bajo), Joey Kramer (batería) y Brad Whitford (guitarra). Conocidos también como "Los chicos malos de Boston" o "Toxic Twins" –apelativo por el que se conoce a sus dos líderes por su histórica adicción a las drogas​ su sonido, agresivamente rítmico, tiene raíces en el blues, y contribuyó a establecer el sonido del hard rock y pop rock entre los años 1981 y 1990.Aunque Perry y Whitford regresaron en 1984 y la banda firmó un contrato de grabación con Geffen Records, no sería hasta el lanzamiento en 1987 del álbum Permanent Vacation en que la banda recuperaría el nivel de popularidad que había experimentado en la década de 1970.A lo largo de las décadas de los 80 y 90, la banda obtendría varios éxitos y ganaría numerosos premios musicales con los álbumes Pump (1989), Get a Grip (1993), y Nine Lives (1997). Su regreso fue descrito como uno de los momentos más importantes y espectaculares de la historia del rock and roll.​ Después de 46 años de dar conciertos, la banda tenía prevista una gira de despedida en 2017,​ pero dicha gira tuvo que ser pospuesta debido a que Steven Tyler tuvo problemas de salud. Hoy en día la banda se encuentra en un residency show en Las Vegas.Aerosmith es una de las bandas con mayor número de ventas de todos los tiempos,11​ también tienen el récord de ser el grupo estadounidense con mayor número de discos de oro y platino. La banda ha conseguido 21 entradas al Top 40 del Billboard Hot 100,12​ nueve números uno en el Mainstream Rock Tracks, cuatro premios Grammy, y diez MTV Video Music Awards. Han sido incluidos en el Salón de la Fama del Rock en el 2001, y en 2005 fueron clasificados n.º 57 en la lista de los 100 mejores artistas de todos los tiempos de la revista Rolling Stone.13​',
    videourl:
      'https://youtube.com/playlist?list=PLEGyvPj66Teon_Vs7wgoIHXP96vx0Ogpa&si=8DxNe9RVdsGAvIcN',
    price: 80,
    coverImg:"aerosmith.gif",
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.aerosmith.com/',
    youtube: 'https://www.youtube.com/channel/UCBxdHQVOaZhUOIj_3gt2FYw',
    facebook: 'https://www.facebook.com/aerosmith',
    instagram: 'https://www.instagram.com/aerosmith',
    twitter: 'https://x.com/Aerosmith',
    spotify: 'https://www.facebook.com/photo',
    deezer: 'https://www.deezer.com/mx/artist/1005',
    apple: 'https://music.apple.com/mx/artist/aerosmith/115386',
    soundcloud: 'https://soundcloud.com/aerosmith',
    tidal: 'https://listen.tidal.com/artist/950',
    wiki: 'https://es.wikipedia.org/wiki/Aerosmith',
    ticketmaster:
      'https://www.ticketmaster.com/aerosmith-tickets/artist/734396',
    tiktok: 'https://www.ticketmaster.com/aerosmith-tickets/artist/734396',
    napster: 'https://music.amazon.com.mx/artists/B000VRIQO4/aerosmith',
  },
  {
    title: 'The Who',
    description:
      'The Who es una banda británica de rock formada en 1962 con el nombre de The Detours que posteriormente cambió a The Who tras la incorporación del baterista Keith Moon. La banda sería de inspiración para Led Zeppelin, de quien Robert Plant sacaría su forma de vestir, además de que Keith Moon sería de inspiración muy importante para John Bonham. De esa manera el grupo, con Roger Daltrey (voz), Pete Townshend (guitarra, teclados y voz), John Entwistle (bajo y voz) y Keith Moon (batería), potenció su carrera escalando a los primeros puestos del ambiente del rock inglés.Fue conocida por sus enérgicas presentaciones en vivo las cuales incluían la destrucción de sus instrumentos.​ The Who ha vendido alrededor de 100 millones de discos en todo el mundo,​ posicionaron 27 sencillos en el Top 40 del Reino Unido y Estados Unidos, así como 17 álbumes en el Top 10, obteniendo 18 discos de oro, 12 de platino y 5 de multi-platino solo en los Estados Unidos..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_ko1hfHaOk_8Okr9OHID6m2KuAWJh7STnM&si=4847CFu8c1uNWZNH',
    price: 80,
    coverImg: 'who.jpg',
    category: 'Rock Clasico',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.thewho.com/',
    youtube: 'https://www.youtube.com/channel/UCUtwj-3S97bj3lYDVxDKtlQ',
    facebook: 'https://www.facebook.com/thewho',
    instagram: 'https://www.instagram.com/officialthewho/',
    twitter: 'https://twitter.com/TheWho',
    spotify: 'https://open.spotify.com/artist/67ea9eGLXYMsO2eYQRui3w',
    deezer: 'https://www.deezer.com/mx/artist/817',
    apple: 'https://music.apple.com/mx/artist/the-who/61499',
    soundcloud: 'https://soundcloud.com/thewho',
    tidal: 'https://listen.tidal.com/artist/24915',
    wiki: 'https://en.wikipedia.org/wiki/The_Who',
    ticketmaster: 'https://www.ticketmaster.com/the-who-tickets/artist/807319',
    tiktok: 'https://www.tiktok.com/@thewho676?lang=es',
    napster: 'https://music.amazon.com/artists/B00GAMSTKU/the-who',
  },
  {
    title: 'The Grateful Dead',
    description:
      'Grateful Dead (también conocidos como "The Dead") fue un grupo de Folk rock estadounidense influido por la psicodelia.1​ El grupo fue creado en 1965 por integrantes de "Mother McCrees Uptown Jug Champions" y "The Soul Warlocks". Grateful Dead se dieron a conocer gracias a su estilo de composición único y ecléctico –que fusionaba elementos del rock, folk, bluegrass, blues, country y jazz.Algunos de los fanáticos del grupo les siguieron de concierto en concierto durante años. Estos seguidores, denominados "Deadheads" (cabezas muertas), fueron conocidos por su dedicación a la música del grupo.',
    videourl:
      'https://youtube.com/playlist?list=PLAxT_DkSwQLvmCcBG7LIu6k0aen1dGjxa&si=0ytfNpOUrWLMa4-E',
    price: 80,
    coverImg: 'gratefuldead.gif',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.dead.net/',
    youtube: 'https://www.youtube.com/@gratefuldead',
    facebook: 'https://www.facebook.com/gratefuldead',
    instagram: 'https://www.instagram.com/gratefuldead/',
    twitter: 'https://twitter.com/GratefulDead',
    spotify: 'https://open.spotify.com/artist/4TMHGUX5WI7OOm53PqSDAT',
    deezer: 'https://www.deezer.com/mx/artist/1785',
    apple: 'https://music.apple.com/mx/artist/grateful-dead/1273063',
    soundcloud: 'https://soundcloud.com/gratefuldead',
    tidal: 'https://listen.tidal.com/artist/222',
    wiki: 'https://es.wikipedia.org/wiki/Grateful_Dead',
    ticketmaster:
      'https://www.ticketmaster.com/the-grateful-dead-tickets/artist/735200',
    tiktok: 'https://www.tiktok.com/@gratefuldead?lang=es',
    napster: 'https://music.amazon.com/artists/B000QJJR18/grateful-dead',
  },
  
]

export default CardData6
