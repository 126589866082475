import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player/lazy';
import Navbar from './Navbar/Navbar';
import LikeButtons from './LikeButtons';
import StarRating from './StarRating';
import { GiBlackFlag } from 'react-icons/gi';
import { SiApplemusic } from 'react-icons/si';
import { useParams } from 'react-router-dom';
import Particle from './Particle';
import BanderaMedia from './BanderaMedia';

import {
  FaTiktok,
  FaWhatsapp,
  FaInstagramSquare,
  FaYoutube,
  FaShare,
  FaSpotify,
  FaFacebook,
  FaWikipediaW
} from 'react-icons/fa';

import { IoTicketSharp } from "react-icons/io5";
import { BsTwitterX } from 'react-icons/bs';
import { loadCardData } from '../utils/loadCardData';
import "./banddetail.css"


const socialMediaLinks = [
  {
    href: band => band.websiteurl,
    icon: <GiBlackFlag />,
    className: 'icons-bandera',
  },
  {
    href: band => band.facebook,
    icon: <FaFacebook />,
    className: 'icons-fb',
  },
  {
    href: band => band.youtube,
    icon: <FaYoutube />,
    className: 'icons-YT',
  },
  {
    href: band => band.instagram,
    icon: <FaInstagramSquare />,
    className: 'icons-instagram',
  },
  {
    href: band => band.twitter,
    icon: <BsTwitterX />,
    className: 'icons-twitter',
  },
  {
    href: band => band.tiktok,
    icon: <FaTiktok />,
    className: 'icons-tiktok',
  },
  {
    href: band => band.ticketmaster,
    icon: <IoTicketSharp />,
    className: 'icons-ticketmaster',
  },
  {
    href: band => band.wiki,
    icon: <FaWikipediaW />,
    className: 'icons-wiki',
  },
  {
    href: band => band.spotify,
    icon: <FaSpotify />,
    className: 'icons-spotify',
  },
  {
    href: band => band.apple,
    icon: <SiApplemusic />,
    className: 'icons-itunes',
  },
  
];

const BandDetail = () => {
  const [band, setBand] = useState(null);
  const [loading, setLoading] = useState(true);
  const { title } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bandsData = await loadCardData();
        const foundBand = bandsData.find(item => item.title === title);
        setBand(foundBand);
      } catch (error) {
        console.error('Error loading bands data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [title]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!band) {
    return <div>Band not found</div>;
  }

  const encodedTitle = encodeURIComponent(band.title);
  const shareUrl = `${window.location.origin}/Bandera/${encodedTitle}`;

  const handleShare = () => {
    if (navigator.share) {
      const shareContent = {
        title: document.title,
        text: `Te Invitamos a disfrutar de ${band.title} con Bandera Musical.`,
        url: shareUrl, // Include the URL so it can be opened directly
      };

      navigator
        .share(shareContent)
        .then(() => console.log('Share successful'))
        .catch(error => console.error('Error sharing:', error));
    } else {
      console.warn('Web Share API not supported');
    }
  };

  return (
    <div>
      {/* <Particle id="particles"/>*/}
      <Navbar />

      <div className="band-card">

        <div>
          <div className="video-container">
            <ReactPlayer
              url={band.videourl}
              className="video"
              width="100%"
              height="100%"
              autoPlay={true}
              controls={true}
            />
          </div>
          </div>
        <div className="title-name">
          <h2 className="title-name" style={{ color: 'white' }}>{band.title}</h2>
        </div>

        <div className="card--titler">
          <p className="card--titler">{band.category}</p>
        </div>
        <div>
          <img
            src={`../images/${band.locationImg}`}
            className="origin-flag"
            alt="location"
          />
        </div>

        <div>
          <StarRating />
        </div>

        <LikeButtons />
        <p className="description" style={{ backgroundColor: "black", fontFamily: 'Iceland', fontSize: '1rem', borderRadius: '10px' }}>{band.description}</p>
        <div>
        {/*  <img
            src={`/images/${band.coverImg}`}
            className="coverImg"
            alt="cover"
          />
          
           <img
            src="/images/tradicional.jpg"
            className="coverImg"
            alt="cover"
          />*/}
          <div className="sharebutton">
            <button
              className="sharebutton-button"
              onClick={handleShare}
              role="link" // ARIA role for link-like elements
            >
              <FaShare />
            </button>

            <a
              className="sharebutton-button"
              href={`whatsapp://send?text=${encodeURIComponent(
                `Te Invitamos a disfrutar de ${band.title} con Bandera Musical. ${shareUrl}`
              )}`}
            >

              <FaWhatsapp className="sharebutton-button-2" />
            </a>
          </div>

          <div className="slidertextdiv">
            <h1 className="slidertext">
              Presiona un Link para visitar la red social del artista
            </h1>
          </div>
          <div className="third-party-icons">
            {socialMediaLinks.map((link, index) => {
              if (!link.href(band)) return null;
              return (
                <a
                  key={index}
                  target="_blank"
                  rel="noreferrer"
                  href={link.href(band)}
                  className={`icons ${link.className}`}
                  id="third-party-grid"
                >
                  {link.icon}
                </a>
              );
            })}
          </div>

          <BanderaMedia style={{ color: "goldenrod" }} />
        </div>
      </div>
    </div>
  );
};

export default BandDetail;
