import Particle from './Particle'
import Navbar from './Navbar/Navbar'
import { Link } from 'react-router-dom'






function Tickets() {
  return (
    <div>
    <Navbar/>
   {/* <Particle id="particles"/>*/}
    <h1 className="title2">Guadalajara y Zapopan</h1>
    <h1 className="title2">Selecciona una Plataforma de Boletos</h1>
    <div className="sponsorcontainer">
    <a 
    href="https://www.ticketnowmexico.com/" 
    className="btn" 
    target="_blank" 
    rel="noopener noreferrer"
  >
    <img
      src="../images/ticketnow.png"
      className="sponsor"
      alt="Ticket Now"
    />
  </a>

  <a 
  href="https://www.eticket.mx/" 
  className="btn" 
  target="_blank" 
  rel="noopener noreferrer"
>
  <img
    src="../images/eticket.jpg"
    className="sponsor"
    alt="E Ticket"
  />
</a>
<a 
href="https://toptickets.pagatusboletos.com/" 
className="btn" 
target="_blank" 
rel="noopener noreferrer"
>
<img
  src="../images/toptickets.jpg"
  className="sponsor"
  alt="Arena VFG"
/>
</a>
<a 
href="https://boletomovil.com/" 
className="btn" 
target="_blank" 
rel="noopener noreferrer"
>
<img
  src="../images/boletomovil.png"
  className="sponsor"
  alt="Boleto Movil"
/>
</a>


<a 
href="https://www.ticketmaster.com.mx/?language=en-mx" 
className="btn" 
target="_blank" 
rel="noopener noreferrer"
>
<img
  src="../images/ticketmaster.jpg"
  className="sponsor"
  alt="Estadio Akron"
/>
</a>

<a
href="https://www.viagogo.com.mx/"
className="btn"
target="_blank" 
rel="noopener noreferrer"
>
<img
  src="../images/viagogo.jpeg"
  className="sponsor"
  alt="Viagogo"
/>
</a>
</div>
<Link 
className="btn" 
to="/merch" 
style={{
  display: 'block',
  textAlign: 'center',
  width: '100%',  // Ensure it spans full width
  padding: '10px 0', // Adjust padding for better layout
}}
>
<button>
  Para Buscar Mercancia Oficial Presiona Aqui !!!
</button>
</Link>
    
    
    </div>
  
  )
}

export default Tickets