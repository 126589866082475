import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Particle from './components/Particle';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import ReactGA from "react-ga4";

// Importación de páginas y componentes
import Intro from './components/Intro';
import Hits from './components/MusicCategories/Hits';
import About from './components/About';
import Contact from './components/Contact';
import Services from './components/Services';
import Bibliography from './components/Bibliography';
import Categories from './components/Categories';
import BandDetail from './components/BandDetail';

// Importación de categorías musicales
import Country from './components/MusicCategories/Country';
import Mariachi from './components/MusicCategories/Mariachi';
import Cumbias from './components/MusicCategories/Cumbias';
import RockEsp from './components/MusicCategories/RockEsp';
import RapTrap from './components/MusicCategories/RapTrap';
import ClassicRock from './components/MusicCategories/ClassicRock';
import Metal from './components/MusicCategories/Metal';
import Alternative from './components/MusicCategories/Alternative';
import Banda from './components/MusicCategories/Banda';
import Norteño from './components/MusicCategories/Norteño';
import Reggae from './components/MusicCategories/Reggae';
import Reggaeton from './components/MusicCategories/Reggaeton';
import ClubMusic from './components/MusicCategories/ClubMusic';
import Pop from './components/MusicCategories/Pop';
import Chill from './components/MusicCategories/Chill';
import Romantica from './components/MusicCategories/Romantica';
import EventTreeCategories2 from './components/EventTreeCategories2';
import Blues from './components/MusicCategories/Blues';
import Clasicas from './components/MusicCategories/Clasicas';
import Jazz from './components/MusicCategories/Jazz';
import Sierreño from './components/MusicCategories/Sierreño';
import Rhythm from './components/MusicCategories/Rhythm';
import Baroque from './components/MusicCategories/Baroque';
import HipHop from './components/MusicCategories/HipHop';
import Rancheras from './components/MusicCategories/Rancheras';
import Femeninas from './components/MusicCategories/Femeninas';
import Sonidero from './components/MusicCategories/Sonidero';
import Salsa from './components/MusicCategories/Salsa';
import Bachatta from './components/MusicCategories/Bachatta';
import Solistas from './components/MusicCategories/Solistas';
import Funk from './components/MusicCategories/Funk';
import Trova from './components/MusicCategories/Trova';
import Punk from './components/MusicCategories/Punk';
import SoftRock from './components/MusicCategories/SoftRock';
import Oldies from './components/MusicCategories/Oldies';
import Artificial from './components/MusicCategories/Artificial';

// Importación de gigspots
import AuditorioTelmex from './components/Gigspots/AuditorioTelmex';
import EstadioAkron from './components/Gigspots/EstadioAkron';
import Ctres from './components/Gigspots/Ctres';
import TeatroDiana from './components/Gigspots/TeatroDiana';
import TresdeMarzo from './components/Gigspots/TresdeMarzo';
import PabellonUDG from './components/Gigspots/PabellonUDG';
import AmericasBar from './components/Gigspots/AmericasBar';
import MusicLive from './components/Gigspots/MusicLive';
import Tickets from './components/Tickets';
import Mercancia from './components/Mercancia';
import Tours from './components/Tours';
// Inicialización de Google Analytics
ReactGA.initialize("G-MM8CRMGQ36");

const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
const isAndroid = /Android/i.test(navigator.userAgent);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Router>
      <Routes>
        <Route path="/" element={<Intro />} />
        <Route path="/myapps" element={<Navigate replace to="/home" />} />
        <Route path="/hits" element={<Hits />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/bibliography" element={<Bibliography />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/Bandera/:title" element={<BandDetail />} />

        {/* Rutas de categorías musicales */}
        <Route path="/country" element={<Country />} />
        <Route path="/mariachi" element={<Mariachi />} />
        <Route path="/banda" element={<Banda />} />
        <Route path="/norteño" element={<Norteño />} />
        <Route path="/reggaeton" element={<Reggaeton />} />
        <Route path="/cumbias" element={<Cumbias />} />
        <Route path="/rockesp" element={<RockEsp />} />
        <Route path="/raptrap" element={<RapTrap />} />
        <Route path="/classicrock" element={<ClassicRock />} />
        <Route path="/pop" element={<Pop />} />
        <Route path="/alternative" element={<Alternative />} />
        <Route path="/reggae" element={<Reggae />} />
        <Route path="/clubmusic" element={<ClubMusic />} />
        <Route path="/chill" element={<Chill />} />
        <Route path="/romantica" element={<Romantica />} />
        <Route path="/events" element={<EventTreeCategories2 />} />
        <Route path="/blues" element={<Blues />} />
        <Route path="/EpocadeOro" element={<Clasicas />} />
        <Route path="/jazz" element={<Jazz />} />
        <Route path="/Metal" element={<Metal />} />
        <Route path="/Sierreño" element={<Sierreño />} />
        <Route path="/Rhythm&Blues" element={<Rhythm />} />
        <Route path="/Baroque" element={<Baroque />} />
        <Route path="/HipHop" element={<HipHop />} />
        <Route path="/Rancheras" element={<Rancheras />} />
        <Route path="/femeninas" element={<Femeninas />} />
        <Route path="/Sonidero" element={<Sonidero />} />
        <Route path="/salsa" element={<Salsa />} />
        <Route path="/bachatta" element={<Bachatta />} />
        <Route path="/solistas" element={<Solistas />} />
        <Route path="/funk" element={<Funk />} />
        <Route path="/trova" element={<Trova />} />
        <Route path="/punk" element={<Punk />} />
        <Route path="/softrock" element={<SoftRock />} />
        <Route path="/oldies" element={<Oldies />} />
        <Route path="/artificial" element={<Artificial />} />

        {/* Rutas de gigspots */}
        <Route path="/teatrodiana" element={<TeatroDiana />} />
        <Route path="/auditoriotelmex" element={<AuditorioTelmex />} />
        <Route path="/Ctres" element={<Ctres />} />
        <Route path="/estadioakron" element={<EstadioAkron />} />
        <Route path="/tresdemarzo" element={<TresdeMarzo />} />
        <Route path="/pabellonudg" element={<PabellonUDG />} />
        <Route path="/americas" element={<AmericasBar />} />
        <Route path="/musiclive" element={<MusicLive />} />
        <Route path="/tickets" element={<Tickets />} />
        <Route path="/merch" element={<Mercancia />} />
        <Route path="/tours" element={<Tours />} />
      </Routes>

          {/* Conditional rendering for banners */}
          {(isIOS || isAndroid) && (
        <div className="mobile-banner">
          {isIOS && (
            <a
              href="https://apps.apple.com/mx/app/banderatv/id6502618057?l=en-GB" // Replace with your App Store link
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="images/apple_appstore.png" // Replace with your App Store banner image
                alt="Download on the App Store"
                className="store-banner"
              />
            </a>
          )}
          {isAndroid && (
            <a
              href="https://play.google.com/store/apps/details?id=com.banderamusical.site" // Replace with your Google Play Store link
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="images/google-play-badge.png" // Replace with your Google Play banner image
                alt="Get it on Google Play"
                className="store-banner"
              />
            </a>
          )}
        </div>
      )}
    </Router>
    <Particle />
  </>
);
