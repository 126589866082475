  const CardData32 = [
    {
      title: 'Tito Puente',
      description:
        'Ernesto Antonio Puente (Nueva York, 20 de abril de 1923-ibídem, 31 de mayo de 2000), conocido como Tito Puente, fue un legendario percusionista estadounidense de origen puertorriqueño. Nombre ineludible del jazz a nivel mundial, desarrolló su trabajo en el campo de la música cubana (son montuno, chachachá, mambo, bolero, pachanga, guaracha), y del jazz afrocubano, el jazz latino y la salsa.',
      videourl:
        'https://youtube.com/playlist?list=OLAK5uy_lyzRM7XkStXcwWB6l9JM5jefPCaFjFWmc',
      price: 36,
      coverImg: 'TitoPuente.jpg',
      category: 'Mambo',
      location: 'Puerto Rico',
      locationImg: 'puertorico.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://es.wikipedia.org/wiki/Tito_Puente',
      youtube: 'https://www.youtube.com/channel/UC-80HNiK3Vwd0zcFcfB8hRw',
      facebook: 'https://www.facebook.com/pages/Tito%20Puente/108467629176892/',
      instagram: 'https://www.instagram.com/_tito_puente_/',
      twitter: 'https://twitter.com/roberjba',
      spotify: 'https://open.spotify.com/artist/6SPpCqM8gOzrtICAxN5NuX',
      deezer: 'https://www.deezer.com/mx/artist/8661',
      apple: 'https://music.apple.com/mx/artist/tito-puente/105119',
      soundcloud: 'https://soundcloud.com/tito-puente-official',
      tidal: 'https://listen.tidal.com/artist/1895',
      wiki: 'https://en.wikipedia.org/wiki/Tito_Puente',
      ticketmaster: 'https://www.ticketmaster.com.mx/search?q=Tito+Puente+JR',
      tiktok: 'https://www.tiktok.com/search?q=Tito%20puente&t=1658084271449',
      napster: 'https://web.napster.com/artist/tito-puente',
    },
    {
      title: 'Celia Cruz',
      description:
        'Úrsula Hilaria Celia de la Caridad de la Santísima Trinidad Cruz Alfonso (La Habana, Cuba; 21 de octubre de 1925-Fort Lee, Nueva Jersey, Estados Unidos; 16 de julio de 2003), más conocida como Celia Cruz, fue una cantante cubana de música tropical. Apodada «La Reina de la Salsa»y «La Guarachera de Cuba», es ampliamente considerada una de las artistas latinas más populares e importantes del siglo XX y un icono de la música latina. Fue una de las máximas exponentes de su género, así como una de las artistas más influyentes de la música de su país.',
      videourl:
        'https://youtube.com/playlist?list=PLF-cRDh2mrlXagibiHOsWNzrXSIFZj6hq',
      price: 36,
      coverImg: 'CeliaCruz.jpg',
      category: 'Mambo',
      location: 'Cuba',
      locationImg: 'cubaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://celiacruz.com/',
      youtube: 'https://www.youtube.com/channel/UC0ici7PK6RBnCUQ2mqVGzKA',
      facebook: 'https://www.facebook.com/CeliaCruzTheQueen',
      instagram: 'https://www.instagram.com/celiacruz/',
      twitter: 'https://twitter.com/CeliaCruz',
      spotify: 'https://open.spotify.com/artist/2weA6hhVqTIN2gSn9PUB9U',
      deezer: 'https://www.deezer.com/mx/artist/5024',
      apple: 'https://music.apple.com/mx/artist/celia-cruz/105181',
      soundcloud: 'https://soundcloud.com/celia-cruz-official',
      tidal: 'https://listen.tidal.com/artist/6072',
      wiki: 'https://en.wikipedia.org/wiki/Celia_Cruz',
      ticketmaster:
        'https://www.ticketmaster.com/Celia-Cruz-tickets/artist/760911',
      napster: 'https://web.napster.com/artist/celia-cruz',
      tiktok: 'https://www.tiktok.com/@soyceliacruz',
    },
  
    {
      title: 'David Zahan',
      description:
        'Andrés David Saldarriaga Salazar es un cantautor salsero Colombiano nacido en la ciudad de Medellín y al que muchos conocen como David Zahan.Empezó el gusto por su género musical (Salsa) desde pequeño cuando su padre escuchaba los LP en casa, su mayor ídolo es Frankie Ruiz, por eso hoy en día David es reconocido mundialmente por su homenaje “Reviviendo a Frankie Ruiz” dicho proyecto se dió a conocer a nivel internacional alcanzando mas de 100 millones de reproducciones en YouTube en menos de 1 año.A parte de este proyecto, Zahan también ha lanzado producciones propias compuestas por él mismo, y ha trabajado con exponentes y productores iconos de la salsa, su último lanzamiento titulado “Media Mujer” sigue sonando en muchas emisoras a nivel internacional..',
  
      videourl:
        'https://youtube.com/playlist?list=OLAK5uy_nasHj9r9_2GOLMBGdtPSbh5MU-5wmeVWc',
      price: 50,
      coverImg: 'davidzahan.jpg',
      category: 'Salsa',
      location: 'Colombia',
      locationImg: 'coloflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://davidzahan.com/',
      youtube: 'https://www.youtube.com/channel/UCz7gmyQOyKzGFCNnN8IZqfg',
      facebook: 'https://www.facebook.com/davidzahan/',
      instagram: 'https://www.instagram.com/davidzahan/',
      twitter: 'https://twitter.com/davidzahan',
      spotify: 'https://open.spotify.com/artist/6GNOUK9TS7x72jEjDsdwHn',
      deezer: 'https://www.deezer.com/en/artist/5567131',
      apple: 'https://music.apple.com/us/artist/david-zahan/828415420',
      soundcloud: 'https://soundcloud.com/davidzahan',
      tidal: 'https://listen.tidal.com/artist/6053868',
      wiki: 'https://www.codiscos.com/artists/David-Zahan',
      ticketmaster: 'https://www.ticketmaster.com.mx/search?q=Salsa',
      tiktok: 'https://www.tiktok.com/@davidzahanfrankieruiz',
      napster: 'https://music.amazon.com.mx/artists/B00INZ6AYO/david-zahan',
    },
    {
      title: 'Chiquito Team Band',
      description:
        'La Chiquito Team Band es un quinteto de salseros liderado por los fundadores Chiquito Timbal (Rafael Berroa) y Manuel Piano (Emmanuel Frias). Los otros miembros del grupo incluyen a Alberto María, Kelvin Saviñon y Lugo Santana, quienes actúan como coro de respaldo y ocasionalmente toman solos, todo frente a una orquesta de salsa que hace bailar. Llaman a su enfoque innovador del género tropical "La Industria Salsera.A pesar de su corta edad cuando formaron la banda, Timbal y Piano habían estado cantando y trabajando profesionalmente desde su infancia. Por separado, habían estado asociados con artistas locales e internacionales como Julián Oro Duro, Bonny Cepeda, José Peña Suazo, Jossie Esteban, Giro, Yiyo Sarante, El Clasicom y otros más. Se encontraban frecuentemente en el circuito, se hicieron amigos y decidieron unirse y formar el concepto musical no solo como cantantes, sino también como productores...',
  
      videourl:
        'https://youtube.com/playlist?list=PLkjGfMxERcCtbx5n9A4wZD7TIDpSY2j7Z',
      price: 50,
      coverImg: 'chiquito.jpg',
      category: 'Salsa',
      location: 'Colombia',
      locationImg: 'coloflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://chiquitoteamband.com/',
      youtube: 'https://www.youtube.com/channel/UCZU8q-bsoCeXtT7n9OY1POg',
      facebook: 'https://www.facebook.com/ChiquitoTeamRD/',
      instagram: 'https://www.instagram.com/chiquitoteamrd/',
      twitter: 'https://twitter.com/davidzahan',
      spotify: 'https://open.spotify.com/artist/0vEYOFlkqy2FUy1UOF7RiV',
      deezer: 'https://www.deezer.com/en/artist/5372016',
      apple:
        'https://music.apple.com/us/album/los-creadores-del-sonido/1346741670',
      soundcloud: 'https://soundcloud.com/chiquitoteamband',
      tidal: 'https://listen.tidal.com/artist/5211617',
      wiki:
        'https://en.wikipedia.org/wiki/List_of_number-one_tropical_songs_of_2017_(Panama)',
      ticketmaster: 'https://www.ticketmaster.com.mx/search?q=Salsa',
      tiktok: 'https://www.tiktok.com/@chiquitoteamrd',
      napster:
        'https://music.amazon.com.mx/artists/B00GV81MZM/chiquito-team-band',
    },
  
    {
      title: 'Luis Enrique',
      description:
        'Nació en Somoto, el 28 de septiembre de 1962. Proveniente de una familia de artistas musicales, hijo de Francisco Luis Mejía Godoy y sobrino de los cantautores nicaragüenses Luis Enrique Mejía Godoy y Carlos Mejía Godoy.Es conocido por su estilo de salsa romántica, Luis Enrique, ha ganado varios discos de oro y platino, tres (3) Grammy Latino, un (1) Grammy americano, cuatro Latin Billboards, tres premios Lo Nuestro, dos premios Ronda y también los premios Huaco, TV y Novelas, Aplausos, ACE y ASCAP.Debido a la compleja situación política en aquella época migra de Nicaragua hacia Estados Unidos junto a su hermano, en el año 1978, instalándose en Los Ángeles. Es ahí, donde inspirado por la música del Jazz y el Rhythm and Blues americano, fue agregando nuevos sonidos a sus composiciones...',
      videourl:
        'https://youtube.com/playlist?list=OLAK5uy_n4UIq0GiahYAyhVTdm5ja-JxZB_SnVF1M',
      price: 50,
      coverImg: 'luisenrique.jpg',
      category: 'Salsa',
      location: 'Nicaragua',
      locationImg: 'nicaragua.png',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://luisenrique-music.com/',
      youtube: 'https://www.youtube.com/@LuisEnriqueVEVO',
      facebook: 'https://www.facebook.com/luisenriquepage',
      instagram: 'https://www.instagram.com/luisenriquemusic/',
      twitter: 'https://twitter.com/LuisEnrique',
      spotify: 'https://open.spotify.com/artist/2mUI4K6csTQd3jieswcmiI',
      deezer: 'https://www.deezer.com/en/artist/65205',
      apple: 'https://music.apple.com/us/artist/luis-enrique/2140016',
      soundcloud: 'https://soundcloud.com/luis-enrique-official',
      tidal: 'https://listen.tidal.com/artist/12517',
      wiki: 'https://es.wikipedia.org/wiki/Luis_Enrique_(cantante)',
      ticketmaster:
        'https://www.ticketmaster.com.mx/luis-enrique-boletos/artist/1483064',
      tiktok: 'https://www.tiktok.com/@salsita_yr',
      napster: 'https://music.amazon.com.mx/artists/B000QKD060/luis-enrique',
    },
    {
      title: 'José Alberto El Canario',
      description:
        'José Alberto El Canario se mudó a Puerto Rico con su familia en la edad de 7 años, e inspirado por la música latina procedió a pulir su canto en la Academia Militar de Las Antillas. Él se trasladó a Nueva York en los inicios de 1970 y cantó con varias orquestas. Él recibió atención internacional como el líder de la Típica 73 en octubre de 1977.José Alberto empezó a armar su propia banda en 1983, y se convirtió en una estrella latina principal en 1984',
      videourl:
        'https://youtube.com/playlist?list=OLAK5uy_kh2CfI0zmncKDwvqUJ8xS4Ls1rzpdr3z8',
      price: 50,
      coverImg: 'canario.jpg',
      category: 'Salsa',
      location: 'Puerto Rico',
      locationImg: 'puertorico.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://davidzahan.com/',
      youtube: 'https://www.youtube.com/channel/UCz7gmyQOyKzGFCNnN8IZqfg',
      facebook: 'https://www.facebook.com/oficialjosealberto',
      instagram: 'https://www.instagram.com/josealberto_official/',
      twitter: 'https://twitter.com/carlitros_125',
      spotify: 'https://open.spotify.com/artist/6TRzinjf5dzE8zA8baPbYz',
      deezer: 'https://www.deezer.com/en/artist/149192',
      apple:
        'https://music.apple.com/us/artist/jos%C3%A9-alberto-el-canario/41979794',
      soundcloud: 'https://soundcloud.com/jose-alberto-el-canario-official',
      tidal: 'https://listen.tidal.com/artist/4810945',
      wiki: 'https://es.wikipedia.org/wiki/Jos%C3%A9_Alberto_el_Canario',
      ticketmaster: 'https://www.ticketmaster.com.mx/search?q=Salsa',
      tiktok: 'https://www.tiktok.com/@joseaelcanarioficial',
      napster:
        'https://music.amazon.com.mx/artists/B004FHZYRI/jos%C3%A9-alberto-el-canario',
    },
    {
      title: 'Alberto Barros',
      description:
        'Alberto Barros, conocido como “El Titán de la Salsa”, es uno de los artistas más representativos de la música colombiana a nivel internacional. Tiene una amplia trayectoria como productor musical, compositor, arreglista, trombonista y cantante. Nació el 30 de noviembre en Barranquilla, Colombia...',
      videourl:
        'https://youtube.com/playlist?list=OLAK5uy_nasHj9r9_2GOLMBGdtPSbh5MU-5wmeVWc',
      price: 50,
      coverImg: 'albertobarros.jpg',
      category: 'Salsa',
      location: 'Colombia',
      locationImg: 'coloflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl:
        'https://cartelera.cdmx.gob.mx/6522/23-02-2023/alberto-barros-el-titan-de-la-salsa',
      youtube: 'https://www.youtube.com/channel/UC7O0FLviJmtefBQ-oNi6IIA',
      facebook: 'https://www.facebook.com/Albertobarrostributo?ref=hl',
      instagram: 'https://www.instagram.com/albertobarrosoficial/',
      twitter: 'https://twitter.com/albertobarrosof',
      spotify: 'https://open.spotify.com/artist/33ZuRLa8CHXZVPJXdQCcul',
      deezer: 'https://www.deezer.com/en/artist/458187',
      apple: 'https://music.apple.com/us/artist/alberto-barros/20128860',
      soundcloud: 'https://soundcloud.com/alberto-barros-official',
      tidal: 'https://listen.tidal.com/artist/4259192',
      wiki:
        'https://cartelera.cdmx.gob.mx/6522/23-02-2023/alberto-barros-el-titan-de-la-salsa',
      ticketmaster: 'https://www.ticketmaster.com.mx/search?q=Salsa',
      tiktok: 'https://www.tiktok.com/@albertobarrosoficial',
      napster: 'https://music.amazon.com.mx/artists/B000QJRHTM/alberto-barros',
    },
    {
      title: 'Orquestra Guayacan',
      description:
        'Guayacán Orquesta es una orquesta de salsa colombiana, fundada en Bogotá, Colombia por razones de mayor facilidad en la época de crear una agrupación, después se radicaron en Cali ciudad de la orquesta. Es dirigida musicalmente por Alexis Lozano, arreglista y productor, quien además ejecuta varios instrumentos musicales de la familia de las cuerdas y de viento..',
      videourl:
        'https://youtube.com/playlist?list=OLAK5uy_n4UIq0GiahYAyhVTdm5ja-JxZB_SnVF1M',
      price: 50,
      coverImg: 'guayacan.jpg',
      category: 'Salsa',
      location: 'Colombia',
      locationImg: 'coloflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl:
        'https://cartelera.cdmx.gob.mx/6522/23-02-2023/alberto-barros-el-titan-de-la-salsa',
      youtube: 'https://www.youtube.com/channel/UC2mCiq5MRRsZQiY7HyAHbkw',
      facebook: 'https://www.facebook.com/guayacanoficial/',
      instagram: 'https://www.instagram.com/guayacanoficial/',
      twitter: 'https://twitter.com/GuayacanOficial',
      spotify: 'https://open.spotify.com/artist/2pZ81eCkqxemIjqqfE1fhE',
      deezer: 'https://www.deezer.com/en/artist/1263498',
      apple: 'https://music.apple.com/us/artist/guayac%C3%A1n-orquesta/251443044',
      soundcloud: 'https://soundcloud.com/guayacanorquestaofficial',
      tidal: 'https://listen.tidal.com/artist/3848972',
      wiki: 'https://es.wikipedia.org/wiki/Orquesta_Guayac%C3%A1n',
      ticketmaster:
        'https://www.ticketmaster.com/orquesta-guayacan-farmingville-15-07-2023/event/00005E7FD97B791F?_ga=2.268643442.2065084436.1684208067-868068493.1679342836',
      tiktok: 'https://www.tiktok.com/@guayacanorquestaoficial',
      napster:
        'https://music.amazon.com.mx/artists/B009696BGI/guayac%C3%A1n-orquesta',
    },
    {
      title: 'La Sonora Ponceña',
      description:
        'La Sonora Ponceña es una orquesta puertorriqueña de música salsa fundada por "Quique" Lucca en 1954. Dirigida por el hijo de "Quique", Papo Lucca, es una de las agrupaciones más importantes del género musical.En 1968 graban su primer disco de 33 RPM llamado Hacheros Pa Un Palo que contaba con los arreglos hechos por el hijo de "Quique", Enrique Arsenio, conocido como Papo Lucca. El disco fue un éxito en Nueva York al igual que su segunda grabación, llamada Fuego en el 23, que fue realizada en 1969.Aconsejado por Louie Ramírez la agrupación inicia una gira por el Caribe interpretando y grabando temas, tales como La Pollera Colorá, del colombiano Wilson Choperena y bombas, merengues, guaguancós y sones. A finales de los años 70 el grupo graba un disco con Celia Cruz titulado La Ceiba...',
      videourl:
        'https://youtube.com/playlist?list=PLdJej8gT_cU7GajWJhH_aTBRXSacWY-5i',
      price: 50,
      coverImg: 'sonoraponcena.jpg',
      category: 'Salsa',
      location: 'Purto Rico',
      locationImg: 'puertorico.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: '',
      youtube: 'https://www.youtube.com/channel/UCOAuvJVHT1Vam34NVjiVbQQ',
      facebook: 'https://www.facebook.com/sonoraponcenapr',
      instagram: 'https://www.instagram.com/sonoraponcenapr/',
      twitter: 'https://twitter.com/sonoraponcena',
      spotify: 'https://open.spotify.com/artist/39qcQ01yJQbaMje70kIiFa',
      deezer: 'https://www.deezer.com/en/artist/85000',
      apple: 'https://music.apple.com/us/artist/sonora-ponce%C3%B1a/6709876',
      soundcloud: 'https://soundcloud.com/lasonoraponcenapr',
      tidal: 'https://listen.tidal.com/artist/4531830',
      wiki: 'https://es.wikipedia.org/wiki/La_Sonora_Ponce%C3%B1a',
      ticketmaster:
        'https://www.ticketmaster.com.mx/la-sonora-poncena-boletos/artist/1993492',
      tiktok:
        'https://www.tiktok.com/search?q=la%20sonora%20ponce%C3%B1a&t=1684215533784',
      napster:
        'https://music.amazon.com.mx/artists/B000V87BZ8/sonora-ponce%C3%B1a',
    },
    {
      title: 'La Sonora Tentacion',
      description:
        'La Sonora Tentación es una orquesta mexicana de música salsa, ampliamente reconocida por su capacidad para cautivar audiencias con su energía inigualable y su impecable talento musical. Originaria de la vibrante ciudad de Guadalajara, Jalisco, esta agrupación ha logrado consolidarse como una de las favoritas para amenizar eventos locales gracias a su estilo único y auténtico que fusiona ritmos tropicales con una interpretación moderna y dinámica. Bajo la dirección de Luis Cazares, un músico apasionado y visionario, y acompañado de otros integrantes igualmente talentosos, La Sonora Tentación destaca no solo por su calidad musical, sino también por su espectacular presencia escénica. Cada presentación se convierte en una experiencia inolvidable, ya que combinan sus melodías cautivadoras con una actuación cargada de energía y carisma que contagia a todos los asistentes.El repertorio del grupo incluye clásicos de la salsa, temas originales y adaptaciones modernas que garantizan un ambiente festivo, ideal para cualquier tipo de evento, desde bodas y fiestas privadas hasta festivales culturales y corporativos. La experiencia y versatilidad de La Sonora Tentación les permite adaptar su espectáculo a las necesidades de cada cliente, asegurando que cada presentación sea un éxito rotundo.Si estás planeando un evento y deseas elevarlo al siguiente nivel con música en vivo que inspire a todos a bailar y disfrutar, La Sonora Tentación es la elección perfecta. Para contrataciones, simplemente llama al número 33 2204 3902 y asegúrate de traer el sabor y el ritmo de la salsa mexicana a tu próxima celebración. ¡No dejes pasar la oportunidad de disfrutar de una experiencia musical inolvidable que atraerá al público y hará de tu evento un verdadero éxito!',
      videourl:
        'https://youtube.com/playlist?list=PLF-cRDh2mrlXTjetfEA-C6Iz2z6Lbe4NX&si=6nqlvQwncEKp4Y4B',
      price: 50,
      coverImg: 'lasonoratentacion.jpg',
      category: 'Salsa',
      location: 'Mexico',
      locationImg: 'mexiflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: '',
      youtube: 'https://www.youtube.com/@sonoratentacion5294',
      facebook: 'https://www.facebook.com/lasensacionalsonoratentacion',
      
    },
    {
      title: 'Yiyo Sarante',
      description:
        'Eduardo Sarante es un cantante dominicano, mejor conocido como Yiyo Sarante. Nació en Baní, provincia de Peravia, República Dominicana. Según la crítica especializada, también lo conocen como La Voz De La Salsa.Yiyo Sarante vivió su niñez en Baní, es hijo de Ángel Sarante y María Perdomo, ocupando el séptimo además es el séptimo puesto entre los nueve hermanos, cinco de ellos músicos.Yiyo Sarante se adentró en el mundo de la música desde temprana edad, cuando participó en diferentes orquestas de República Dominicana. En 1999, debutó como cantante y realizó presentaciones en la zona turística de Bávaro en su país natal. En 2003, pasó a formar parte de la orquesta Oro Duro, de uno de sus hermanos músicos...',
  
      videourl:
        'https://youtube.com/playlist?list=OLAK5uy_lo8ErAm1QmcfbclOnFg4tO5HSp5xPO6gQ',
      price: 50,
      coverImg: 'yiyosarante.jpg',
      category: 'Salsa',
      location: 'Republica Dominicana',
      locationImg: 'dominican.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl:
        'https://lasuper104fm.com/2021/06/19/biografia-de-yiyo-sarante/',
      youtube: 'https://www.youtube.com/channel/UC3RhQS-TYlc91Cpmyz_2cWA',
      facebook: 'https://www.facebook.com/yiyosarante',
      instagram: 'https://www.instagram.com/yiyosarante/',
      twitter: 'https://twitter.com/yiyosarante?lang=en',
      spotify: 'https://open.spotify.com/artist/2rwLjVHS15sfzciKXXNbgA',
      deezer: 'https://www.deezer.com/en/artist/1634132',
      apple: 'https://music.apple.com/us/artist/yiyo-sarante/500109790',
      soundcloud: 'https://soundcloud.com/yiyosarante',
      tidal: 'https://listen.tidal.com/artist/4504389',
      wiki: 'https://lasuper104fm.com/2021/06/19/biografia-de-yiyo-sarante/',
      ticketmaster: 'https://www.ticketmaster.com.mx/search?q=Salsa',
      tiktok: 'https://www.tiktok.com/@oficialyiyosarante',
      napster: 'https://music.amazon.com.mx/artists/B007AHLP8W/yiyo-sarante',
    },
    {
      title: 'Marc Anthony',
      description:
        'Marco Antonio Muñiz Rivera (Nueva York, 16 de septiembre de 1968), más conocido como Marc Anthony, es un cantautor y actor estadounidense de origen puertorriqueño, cuyas canciones van desde la salsa, pasando por el bolero, la balada y el pop. Empezó su trayectoria discográfica en los géneros del hip hop con el dúo Little Louie & Marc Anthony, llegando al N.º 1 de las listas estadounidenses en 1991. Ralph Mercado lo contrató para su sello RMM en esa fecha y lo lanzó cantando salsa, consiguiendo un gran éxito que duró años, desde su primer sencillo con la canción "Hasta que te conocí" de Juan Gabriel que fue N.º 1 en ventas..',
  
      videourl:
        'https://youtube.com/playlist?list=PLWgebRsx5qvG31UoLwAFtU7FNGPy8XNPS',
      price: 50,
      coverImg: 'marcanthony.jpg',
      category: 'Salsa',
      location: 'Colombia',
      locationImg: 'coloflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.marcanthonyonline.com/en/',
      youtube: 'https://www.youtube.com/channel/UCZU8q-bsoCeXtT7n9OY1POg',
      facebook: 'https://www.facebook.com/officialmarcanthony/',
      instagram: 'https://www.instagram.com/marcanthony/',
      twitter: 'https://twitter.com/MarcAnthony',
      spotify:
        'https://open.spotify.com/artist/4wLXwxDeWQ8mtUIRPxGiD6?autoplay=true',
      deezer: 'https://www.deezer.com/en/artist/112',
      apple: 'https://music.apple.com/us/artist/marc-anthony/217029',
      soundcloud: 'https://soundcloud.com/marcanthonyofficial',
      tidal: 'https://listen.tidal.com/artist/930',
      wiki: 'https://es.wikipedia.org/wiki/Marc_Anthony',
      ticketmaster:
        'https://www.ticketmaster.com/marc-anthony-tickets/artist/759949',
      tiktok: 'https://www.tiktok.com/@marcanthony',
      napster: 'https://music.amazon.com.mx/artists/B000QJIF6G/marc-anthony',
    },

  
]
export default CardData32
