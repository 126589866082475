import React from 'react'
import Navbar from './Navbar/Navbar'
import BanderaMedia from './BanderaMedia'
import Particle from './Particle'

export default function Contact() {
  return (
    <div>
    {/* <Particle id="particles"/>*/}
      <Navbar/>
      <BanderaMedia/>
    </div>
  )
}
