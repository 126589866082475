import Particle from './Particle'
import Navbar from './Navbar/Navbar'
import { Link } from 'react-router-dom'






function Mercancia() {
  return (
    <div>
    <Navbar/>
   {/* <Particle id="particles"/>*/}
    <h1 className="title2">Selecciona una Empresa para Comprar Tu Mercancia</h1>
    <div className="sponsorcontainer">
    <a 
    href="https://maadshirts.com/" 
    className="btn" 
    target="_blank" 
    rel="noopener noreferrer"
  >
    <img
      src="../images/maad.jpg"
      className="sponsor"
      alt="Maad Shirts"
    />
  </a>

  <a 
  href="https://malaracha.com/" 
  className="btn" 
  target="_blank" 
  rel="noopener noreferrer"
>
  <img
    src="../images/malaracha.png"
    className="sponsor"
    alt="E Ticket"
  />
</a>

<a 
href="https://elrockerito.com/" 
className="btn" 
target="_blank" 
rel="noopener noreferrer"
>
<img
  src="../images/elrockerito.jpg"
  className="sponsor"
  alt="Boleto Movil"
/>
</a>


<a 
href="https://toxic.mx/" 
className="btn" 
target="_blank" 
rel="noopener noreferrer"
>
<img
  src="../images/toxic.jpg"
  className="sponsor"
  alt="Estadio Akron"
/>
</a>

<a
href="https://rockososhop.com/"
className="btn"
target="_blank" 
rel="noopener noreferrer"
>
<img
  src="../images/rockoso.jpg"
  className="sponsor"
  alt="Viagogo"
/>
</a>
<a
href="https://rockcitymx.mercadoshops.com.mx/"
className="btn"
target="_blank" 
rel="noopener noreferrer"
>
<img
  src="../images/rockcity.jpg"
  className="sponsor"
  alt="Viagogo"
/>
</a>
<a
href="https://kingmonster.com/"
className="btn"
target="_blank" 
rel="noopener noreferrer"
>
<img
  src="../images/monster.jpg"
  className="sponsor"
  alt="Viagogo"
/>
</a>
<a
href="https://www.missionbrand.com/"
className="btn"
target="_blank" 
rel="noopener noreferrer"
>
<img
  src="../images/missionbrand.jpg"
  className="sponsor"
  alt="Viagogo"
/>
</a>
<a
href="https://www.clothingsalego.com/"
className="btn"
target="_blank" 
rel="noopener noreferrer"
>
<img
  src="../images/rock-life.jpg"
  className="sponsor"
  alt="Viagogo"
/>
</a>
</div>
<Link 
className="btn" 
to="/tours" 
style={{
  display: 'block',
  textAlign: 'center',
  width: '100%',  // Ensure it spans full width
  padding: '10px 0', // Adjust padding for better layout
}}
>
<button>
  Para Buscar Boletos para un Tour Presiona Aqui !!!
</button>
</Link>
    
    
    </div>
  
  )
}

export default Mercancia