const CardData39= [
    {
        title: 'The Dor Brothers',
        description:
          'Los Dor Brothers son pioneros en la producción de contenido visual innovador, destacándose como referentes en la comunidad de imágenes generadas por inteligencia artificial. Su carrera comenzó en el mundo de la música y la producción de videos tradicionales, explorando posteriormente la animación con software 3D. Sin embargo, fue en marzo de 2022 cuando revolucionaron su trayectoria al adoptar herramientas de generación de imágenes con IA, convirtiéndose en algunos de los primeros usuarios de plataformas como Stable Diffusion y Midjourney.Hoy en día, su compañía es reconocida globalmente por haber producido más de 200 clips que acumulan decenas de millones de vistas en todo el mundo. Han trabajado con nombres de renombre internacional como Snoop Dogg, The Game, Hugo Boss, French Montana, SiM e INSOMNIUM, consolidándose como líderes en la creación de contenido visual impactante.Además, los Dor Brothers han sido oradores en prestigiosas conferencias para empresas como Microsoft, demostrando su expertise y visión en el campo de la inteligencia artificial aplicada al arte. Como cofundadores de Event Station, han llevado la personalización artística a otro nivel mediante un fotomatón físico que ofrece obras únicas.Su talento trasciende el ámbito digital, ya que también producen comerciales de alta calidad para importantes marcas internacionales, los cuales se han exhibido en lugares icónicos como las calles de Milán y durante eventos deportivos de gran envergadura. Innovación, creatividad y excelencia definen a los Dor Brothers como líderes indiscutibles en la intersección del arte y la tecnología..',
        videourl:
          'https://youtube.com/playlist?list=PLF-cRDh2mrlX_jfkXxhg39BJM2PpeSgCw&si=vdXLAwUvAosgQxJi',
        price: 36,
        coverImg: 'dorbrothers.jpg',
        category: 'AI',
        location: 'USA',
        locationImg: 'usaflag.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://www.thedorbrothers.com/',
        youtube: 'https://www.youtube.com/@thedorbrothers',
        facebook: 'https://www.facebook.com/Thedorbrothers',
        instagram: 'https://www.instagram.com/thedorbrothers/',
        twitter: 'https://x.com/thedorbrothers',
        tiktok: 'https://www.tiktok.com/@.thedorbrothers',
      },
      {
        title: 'Pink Floyd',
        description:
          'Pink Floyd fue una banda de rock británica, fundada en Londres en 1965. Considerada un ícono cultural del siglo XX y una de las bandas más influyentes, exitosas y aclamadas en la historia de la música popular, obtuvo gran popularidad dentro del circuito underground gracias a su música psicodélica y espacial, que con el paso del tiempo evolucionó hacia el rock progresivo y el rock sinfónico adquiriendo la popularidad con la que hoy son recordados. Es conocida por sus canciones de alto contenido filosófico, a veces de crítica política, junto a la experimentación sonora, las innovadoras portadas de sus discos y sus elaborados espectáculos en vivo. Sus ventas sobrepasan los 280 millones de álbumes vendidos en todo el mundo, 97.5 millones de ellos solamente en los Estados Unidos,convirtiéndose en una de las bandas con más ventas en la historia.',
        videourl:
          'https://youtube.com/playlist?list=PLMXnSDwMoUMtesFODEN4G6ZkSkDajN2Pt&si=nj726oeIHs22L_pv',
        price: 80,
        coverImg: 'pinkfloyd.gif',
        category: 'Rock',
        location: 'UK',
        locationImg: 'england.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://www.pinkfloyd.com/',
        youtube: 'https://www.youtube.com/channel/UCY2qt3dw2TQJxvBrDiYGHdQ',
        facebook: 'https://www.facebook.com/pinkfloyd',
        instagram: 'https://www.instagram.com/pinkfloyd/',
        twitter: 'https://twitter.com/pinkfloyd',
        spotify: 'https://open.spotify.com/artist/0k17h0D3J5VfsdmQ1iZtE9',
        deezer: 'https://www.deezer.com/mx/artist/860',
        apple: 'https://music.apple.com/mx/artist/pink-floyd/487143',
        soundcloud: 'https://soundcloud.com/officialpinkfloyd',
        tidal: 'https://listen.tidal.com/artist/9706',
        wiki: 'https://es.wikipedia.org/wiki/Pink_Floyd',
        ticketmaster:
          'https://www.ticketmaster.com/pink-floyd-tickets/artist/768805',
        tiktok: 'https://www.tiktok.com/@pink_floyd90?lang=es',
        napster: 'https://music.amazon.com/artists/B000QJPQK4/pink-floyd',
      },
      {
        title: 'The BonsAI Effect',
        description:
          'The BonsAi Effect está dedicado a explorar el fascinante mundo del arte generado por inteligencia artificial. Con una perfecta combinación de tecnología y creatividad, este canal muestra el asombroso potencial de la IA para crear obras de arte impresionantes y provocadoras. Adéntrate en un universo donde los algoritmos y la expresión artística se fusionan, y sé testigo de las impresionantes capacidades del arte generado por IA..',
        videourl:
          'https://youtube.com/playlist?list=PLMXnSDwMoUMtesFODEN4G6ZkSkDajN2Pt&si=nj726oeIHs22L_pv',
        price: 80,
        coverImg: 'bonsAI.png',
        category: 'AI',
        location: 'China',
        locationImg: 'england.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://www.youtube.com/@bonsai-effect',
        youtube: 'https://www.youtube.com/@bonsai-effect',
        facebook: 'https://www.facebook.com/profile.php?id=61560388537215',
        instagram: 'https://www.instagram.com/pinkfloyd/',
        twitter: 'https://x.com/ekuZhong',      
      },
      {
        title: 'Nacho AI',
        description:
          'Los videos musicales creados con inteligencia artificial (IA) están transformando la industria de la música y el arte visual de formas nunca antes vistas. Gracias a herramientas avanzadas como generadores de imágenes y videos basados en aprendizaje profundo, los artistas ahora pueden crear mundos visuales surrealistas, animaciones hiperrealistas y efectos especiales complejos que antes requerían equipos y presupuestos multimillonarios. La IA permite personalizar videos en tiempo real, generando contenido único adaptado a cada espectador, y también facilita la creación de escenarios virtuales, personajes y narrativas que evolucionan de manera dinámica según el tono y ritmo de la música. Este avance no solo está democratizando el acceso a la producción audiovisual, sino que también está fomentando una nueva ola de creatividad al permitir que los músicos y directores experimenten con estilos visuales que rompen las barreras del arte tradicional. En el futuro, la integración de IA con tecnologías como realidad virtual (VR) y realidad aumentada (AR) llevará los videos musicales a un nivel completamente nuevo, permitiendo experiencias inmersivas donde los espectadores puedan interactuar con los elementos visuales o incluso participar en la narrativa del video. Además, la IA será clave en la predicción de tendencias y en la creación de contenido optimizado para las plataformas digitales, lo que ayudará a los artistas a conectar más profundamente con sus audiencias. Sin embargo, este avance también plantea preguntas éticas sobre la autenticidad y el papel humano en la creación artística, pero, sin duda, marcará una nueva era en la evolución del arte y la música.',
        videourl:
          'https://youtube.com/playlist?list=PL4V2obbh3gnWgl6ZLIBfzgQiFC7cdviHw&si=mAaLzI8E8KTKd1ve',
        price: 80,
        coverImg: 'nacho.jpg',
        category: 'AI',
        location: 'Ukraine',
        locationImg: 'ukraine.jpg',
        openSpots: null,
        likes: 1000,
        websiteurl: 'https://nacho-ai.com/',
        youtube: 'https://www.youtube.com/@nuuttikataja666',
        facebook: 'https://www.facebook.com/freedomfightersofficial',
        instagram: 'https://www.instagram.com/djfreedomfighters',
        twitter: 'https://x.com/NotFreedomFight', 
        spotify:'https://open.spotify.com/artist/7Fmu1wTZGOG9b2w5qvM9XR'     
      },
    
]
   export default CardData39;